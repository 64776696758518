import Vue from 'vue';
import _ from 'lodash';
import api from '../api/result.api';
import ResultModel from '../models/result.model';

export default {
    namespaced: true,
    state: {
        userResults: {},
        comments: {},
        currentResult: {},
        allResults: {
            page: 1,
            page_size: 20,
            total: 0,
            finished: false,
            queryString: null,
            data: {}
        }
    },
    getters: {
        allUserResults({ userResults }, getters, rootState, rootGetters) {
            return _.mapValues(userResults, (results) => results.map((res) => new ResultModel(res, rootState, rootGetters)));
        },
        currentResult(state, getters, rootState, rootGetters) {
            return _.isEmpty(state.currentResult) ? {} : new ResultModel(state.currentResult, rootState, rootGetters);
        },
        allResults(state, getters, rootState, rootGetters) {
            const resultsToUse = state.allResults.data || {};

            return _(resultsToUse)
                .map((result) => {
                    return new ResultModel(result, rootState, rootGetters);
                })
                .mapKeys((res) => res.orderId)
                .value();
        },
        page(state) {
            return state.allResults.page;
        },
        pageSize(state) {
            return state.allResults.page_size;
        },
        total(state) {
            return state.allResults.total;
        },
        finished(state) {
            return state.allResults.finished;
        },
        queryString(state) {
            return state.allResults.queryString;
        }
    },
    mutations: {
        // we suppose for now all the time we get a result we get it with historical data
        setResult(state, result) {
            Vue.set(state, 'currentResult', result);

            if (state.allResults.data[result.id]) {
                Vue.set(state.allResults.data, result.id, result);
            }
        },
        setAllResults(state, results) {
            Vue.set(state.allResults, 'data', {});

            _.each(results.data, (result) => {
                Vue.set(state.allResults.data, result.id, result);
            });

            Vue.set(state.allResults, 'page', results.page || 1);
            Vue.set(state.allResults, 'page_size', results.page_size || 20);
            Vue.set(state.allResults, 'total', results.total || 0);
            Vue.set(state.allResults, 'finished', results.finished || false);
            Vue.set(state.allResults, 'queryString', results.query_string || null);
        },
        setPagination(state, { page, pageSize, finished, queryString }) {
            Vue.set(state.allResults, 'page', page || 1);
            Vue.set(state.allResults, 'page_size', pageSize || 20);
            Vue.set(state.allResults, 'finished', finished || false);
            Vue.set(state.allResults, 'queryString', queryString || null);
        },
        updateResult(state, result) {
            if (state.currentResult && state.currentResult.id === result.id) {
                Vue.set(state, 'currentResult', { ...state.currentResult, ...result });
            }

            if (state.allResults.data[result.id]) {
                Vue.set(state.allResults.data, result.id, { ...state.allResults.data[result.id], ...result });
            }

            if (state.userResults[result.userId]?.length) {
                const userResults = state.userResults[result.userId];

                const resultIndex = userResults.findIndex((res) => res.id === result.id);

                if (resultIndex >= 0 - 1) {
                    Vue.set(state.userResults[result.userId], resultIndex, { ...userResults[resultIndex], ...result });
                } else {
                    state.userResults[result.userId].push(result);
                }
            }
        },
        setUserResults(state, { userId, userResults }) {
            Vue.set(state.userResults, userId, userResults || []);
        },
        updateResultFinishedStatus(state, { resultId, finished }) {
            if (state.currentResult && state.currentResult.id === resultId) {
                Vue.set(state.currentResult, 'finished', finished);
            }

            if (state.allResults.data[resultId]) {
                Vue.set(state.allResults.data[resultId], 'finished', finished);
            }

            if (state.userResults[state.currentResult.userId]?.length) {
                const userResults = state.userResults[state.currentResult.userId];

                const resultIndex = userResults.findIndex((res) => res.id === resultId);

                if (resultIndex !== -1) {
                    Vue.set(state.userResults[state.currentResult.userId], resultIndex, { ...userResults[resultIndex], finished });
                }
            }
        }
    },
    actions: {
        async getResultWithHistoricalData({ commit }, { resultId, orderId } = {}) {
            commit('activateLoading', 'result/getResult', { root: true });

            try {
                let response = null;

                if (resultId) {
                    response = await api.getResultWithHistoricalData(resultId, true);
                } else if (orderId) {
                    response = await api.getResultWithHistoricalDataForOrderId(orderId, true);
                }

                if (response) commit('setResult', response);
            } finally {
                commit('deactivateLoading', 'result/getResult', { root: true });
            }
        },
        async updateResultAction({ commit }, { resultId, result }) {
            commit('activateLoading', 'result/updateResult', { root: true });

            try {
                const dbResult = _.cloneDeep(result);
                if (dbResult.result) Vue.delete(dbResult, 'result');
                if (dbResult.user) Vue.delete(dbResult, 'user');
                if (dbResult.order) Vue.delete(dbResult, 'order');

                await api.updateResult(resultId, dbResult);

                commit('updateResult', { id: resultId, orderId: resultId, ...result });
            } finally {
                commit('deactivateLoading', 'result/updateResult', { root: true });
            }
        },
        async assignDoctor({ commit }, { resultId, doctorId }) {
            commit('activateLoading', 'result/assignDoctor', { root: true });

            try {
                const response = await api.assignDoctorToResult(resultId, doctorId);
                commit('updateResult', response);
            } finally {
                commit('deactivateLoading', 'result/assignDoctor', { root: true });
            }
        },
        async updateCommentOnResult({ commit }, { resultId, comment }) {
            commit('activateLoading', 'result/updateCommentOnResult', { root: true });

            try {
                const response = await api.updateCommentOnResult(resultId, comment);
                commit('updateResult', response);
            } finally {
                commit('deactivateLoading', 'result/updateCommentOnResult', { root: true });
            }
        },
        async unassignDoctor({ commit }, { resultId }) {
            commit('activateLoading', 'result/unassignDoctor', { root: true });

            try {
                const response = await api.unassignDoctorToResult(resultId);
                commit('updateResult', response);
            } finally {
                commit('deactivateLoading', 'result/unassignDoctor', { root: true });
            }
        },
        // async deleteResult({ commit, dispatch }, id) {
        //     commit('activateLoading', `user/deleteResult/${id}`, { root: true });
        //     try {
        //         await api.deleteResult(id);
        //         dispatch('getAllResults');
        //     } finally {
        //         commit('deactivateLoading', `user/deleteResult/${id}`, { root: true });
        //     }
        // },
        async getAllResults(
            { commit, state },
            {
                withLoading = true,
                page = state.allResults.page,
                pageSize = state.allResults.page_size,
                queryString = state.allResults.queryString,
                finished = state.allResults.finished
            } = {}
        ) {
            if (withLoading) commit('activateLoading', 'result/getAllResults', { root: true });

            if (
                page === state.allResults.page &&
                pageSize === state.allResults.page_size &&
                queryString &&
                queryString === state.allResults.queryString &&
                finished === state.allResults.finished
            ) {
                commit('deactivateLoading', 'result/getAllResults', { root: true });
                return;
            }

            try {
                commit('setPagination', { page, pageSize, queryString, finished });

                const response = await api.getAllResults(true, {
                    finished,
                    page,
                    page_size: pageSize,
                    query_string: queryString
                });

                commit('setAllResults', response);
            } finally {
                if (withLoading) commit('deactivateLoading', 'result/getAllResults', { root: true });
            }
        },
        async getAllResultsForAUser({ commit }, userId) {
            commit('activateLoading', 'result/getAllResults', { root: true });
            try {
                const response = await api.getAllResultsForAUser(userId);
                if (response && response.length) commit('setUserResults', { userId, userResults: response });
            } finally {
                commit('deactivateLoading', 'result/getAllResults', { root: true });
            }
        },
        async exportResult({ commit }, { resultId, fileType = 'pdf' }) {
            commit('activateLoading', 'result/exportResult', { root: true });
            try {
                const file = await api.exportResult(resultId, fileType);

                return file;
            } finally {
                commit('deactivateLoading', 'result/exportResult', { root: true });
            }
        },
        async finishResult({ commit }, { id, comment } = {}) {
            commit('activateLoading', 'result/finishResult', { root: true });
            try {
                const response = await api.finishResult({ id, comment });
                commit('updateResultFinishedStatus', { resultId: id, finished: true });

                return response;
            } finally {
                commit('deactivateLoading', 'result/finishResult', { root: true });
            }
        }
    }
};
