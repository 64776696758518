/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import client from './client';
import { getInstance } from '../../utils/auth.instance';

let apiDomain = process.env.VUE_APP_API_URL;
apiDomain = apiDomain[apiDomain.length - 1] === '/' ? apiDomain : `${apiDomain}/`;

axios.defaults.withCredentials = true;
export default {
    async get(method, customHeaderObject = null, handleAsBlob = false) {
        const requestConfig = {
            headers: populateHeader(customHeaderObject)
        };

        if (handleAsBlob) {
            requestConfig.responseType = 'blob';
        }

        const res = await axios.get(`${apiDomain}${method}`, requestConfig);
        return client.parseResponse(res);
    },
    async post(method, body = null, customHeaderObject = null, handleAsBlob = false) {
        const requestConfig = {
            headers: populateHeader(customHeaderObject)
        };

        if (handleAsBlob) {
            requestConfig.responseType = 'blob';
        }

        const res = await axios.post(`${apiDomain}${method}`, body, requestConfig);
        return client.parseResponse(res);
    },
    async delete(method, customHeaderObject = null) {
        const res = await axios.delete(`${apiDomain}${method}`, { headers: populateHeader(customHeaderObject) });
        return client.parseResponse(res);
    },
    async getStatus(customHeaderObject = null) {
        const res = await axios.get(`${apiDomain}signup-status`, { headers: populateHeader(customHeaderObject) });
        return client.parseResponse(res);
    },
    async auth(customHeaderObject = null) {
        const res = await axios.post(`${apiDomain}auth`, {}, { headers: populateHeader(customHeaderObject) });
        return client.parseResponse(res);
    }
};

const populateHeader = (customHeaderObject = null) => {
    const authInstance = getInstance();
    const accessToken = authInstance?.authState?.user?.accessToken;

    const header = {
        Authorization: `Bearer ${accessToken}`
    };

    if (customHeaderObject) {
        _.forEach(customHeaderObject, (value, key) => {
            if (_.isBoolean(value)) {
                header[key] = value ? 1 : 0;
            } else {
                header[key] = value;
            }
        });
    }

    return header;
};
