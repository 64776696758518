import api from './api';

export default {
    async getDashboardSession() {
        const payload = {
            redirectTo: window.location.href
        };

        const response = await api.post('stripe/get-dashboard-session', payload);
        return response;
    },
    async getStripePaymentIntent() {
        const response = await api.get('stripe/setup-intent');
        return response;
    },
    async getLolaSubscriptions(usedFor = 'access') {
        const response = await api.get(`stripe/subscriptions/${usedFor}`);
        return response;
    },
    async setDefaultPayment(payload) {
        const response = await api.post('stripe/set-default-payment', payload);
        return response;
    },
    async paySubscriptions(payload) {
        const response = await api.post('stripe/pay-subscriptions', payload);
        return response;
    },
    async retrieveStripeSetupIntent(payload) {
        const response = await api.post('stripe/retrieve-setup-intent', payload);
        return response;
    },
    async createCheckoutSession(payload) {
        const response = await api.post('stripe/create-checkout-session', payload);
        return response;
    }
};
