import api from './api';

export default {
    async getOrder(id, withUser = false) {
        const response = await api.get(`order/${id}`, { 'with-user': withUser });
        return response;
    },

    async getOrderWithUser(id) {
        const response = await api.get(`order/${id}`, { 'with-user': true });
        return response;
    },

    async updateOrder(id, newOrder) {
        const response = await api.post(`order/update/${id}`, newOrder);
        return response;
    },

    // async deleteOrder(id) {
    //     const response = await api.get(`order/delete/${id}`);
    //     return response;
    // },

    async updateOrderStatus(id, status) {
        const response = await api.get(`order/update-status/${id}?status=${status}`);
        return response;
    },

    async getOrdersForUser(userId) {
        const response = await api.get(`order/get/all/for-user/${userId}`);
        return response;
    },

    async getAllOrders(withUser = false, { page = 1, page_size = 20, query_string = null, status = null, results = null, outliers = null } = {}) {
        const response = await api.post('order/get/all', { page, page_size, query_string, status, results, outliers }, { 'with-user': withUser });
        return response;
    },

    async addOrUpdateAdditionalInfoToOrder(id, additionalInfo) {
        const response = await api.post(`order/additional-info/${id}`, additionalInfo);

        return response;
    },

    async deleteAdditionalInfoFromOrder(id, date) {
        const response = await api.delete(`order/additional-info/${id}/${date}`);

        return response;
    },

    async generateQRCodeForOrder(id) {
        const response = await api.get(`order/qr-code/${id}`, null, true);

        return response;
    },

    async generateKitDispatchCSV(orderIds = [], option = 'download', addQR = false) {
        // option can be upload or download
        // upload will upload the file on the provider and download returns the CSV file
        const response = await api.post('order/generate-kit-dispatch-csv', { orderIds, option, addQR }, {}, option === 'download');

        return response;
    }
};
