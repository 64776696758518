/* eslint-disable no-unused-vars */
import Vue from 'vue';
import api from '../api/menstruation.api';
import MenstruationModel from '../models/menstruation.model';

export default {
    namespaced: true,
    state: {
        currentUserMenstruation: null
    },
    getters: {
        currentUserMenstruation({ currentUserMenstruation }, getters, rootState, rootGetters) {
            return currentUserMenstruation ? new MenstruationModel(currentUserMenstruation, rootState, rootGetters) : null;
        }
    },
    mutations: {
        setMenstruationForCurrentUser(state, menstruation) {
            Vue.set(state, 'currentUserMenstruation', menstruation);
        },
        updateMenstruationForCurrentUser(state, menstruation) {
            Vue.set(state, 'currentUserMenstruation', { ...state.currentUserMenstruation, ...menstruation });
        }
    },
    actions: {
        async getMenstruationForCurrentUser({ commit }) {
            commit('activateLoading', 'menstruation/getMenstruationForCurrentUser', { root: true });
            try {
                const response = await api.getMenstruationForCurrentUser();
                commit('setMenstruationForCurrentUser', response);
            } finally {
                commit('deactivateLoading', 'menstruation/getMenstruationForCurrentUser', { root: true });
            }
        },
        async createMenstruationForCurrentUser({ commit }, payload) {
            commit('activateLoading', 'menstruation/createMenstruationForCurrentUser', { root: true });
            try {
                const response = await api.createMenstruationForCurrentUser(payload);
                commit('setMenstruationForCurrentUser', response);
            } finally {
                commit('deactivateLoading', 'menstruation/createMenstruationForCurrentUser', { root: true });
            }
        },
        async updateMenstruationForCurrentUser({ commit }, payload) {
            commit('activateLoading', 'menstruation/updateMenstruationForCurrentUser', { root: true });
            try {
                const response = await api.updateMenstruationForCurrentUser(payload);
                commit('updateMenstruationForCurrentUser', response);
            } finally {
                commit('deactivateLoading', 'menstruation/updateMenstruationForCurrentUser', { root: true });
            }
        },
        async updateMenstruationDataForCurrentUser({ commit }, payload) {
            commit('activateLoading', 'menstruation/updateMenstruationDataForCurrentUser', { root: true });
            try {
                const response = await api.updateMenstruationDataForCurrentUser(payload);
                commit('updateMenstruationForCurrentUser', response);
            } finally {
                commit('deactivateLoading', 'menstruation/updateMenstruationDataForCurrentUser', { root: true });
            }
        }
    }
};
