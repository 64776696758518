import { cloneDeep } from 'lodash';

export default class Question {
    static get allowedTypes() {
        return [
            {
                value: 'single',
                text: 'Single'
            },
            {
                value: 'multiple',
                text: 'Multiple'
            },
            {
                value: 'text',
                text: 'Text'
            },
            {
                value: 'number',
                text: 'Number'
            },
            {
                value: 'date',
                text: 'Date'
            }
        ];
    }

    constructor(data) {
        this.data = data;
    }

    get key() {
        return this.data.key || this.data.title.replace(/\s/g, '_');
    }

    get title() {
        return this.data.title;
    }

    get type() {
        return this.data.type;
    }

    get options() {
        return this.data.options;
    }

    get defaultValue() {
        return this.type === 'multiple' ? (this.data.defaultValue ? [this.data.defaultValue] : []) : this.data.defaultValue;
    }

    hasOtherOption() {
        return !!this.data?.otherOption?.label;
    }

    get otherOptionType() {
        return this.data.otherOption.type;
    }

    get otherOptionLabel() {
        return this.data.otherOption.label || 'Other';
    }

    isRequired() {
        return !!this.data.required;
    }

    isText() {
        return this.type === 'text';
    }

    isRadio() {
        return this.type === 'single';
    }

    isCheckbox() {
        return this.type === 'multiple';
    }

    isNumber() {
        return this.type === 'number';
    }

    isDate() {
        return this.type === 'date';
    }

    export() {
        return cloneDeep(this.data);
    }
}
