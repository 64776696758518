<template>
    <div>
        <article class="w-100">
            <div class="d-flex flex-column flex-lg-row w-100">
                <div class="d-flex flex-row w-100">
                    <div class="card card-body text-left col p-3 pt-0 mx-2 mx-sm-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-user-tag fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.nameColumn }}</label>
                        <span>{{ patientToUse?.name }}</span>
                        <small class="mt-2"
                            ><span><b>ID:</b> {{ patientToUse?.id }}</span></small
                        >
                        <small
                            ><span><b>Email:</b> {{ patientToUse?.email }}</span></small
                        >
                        <small
                            ><span><b>Lab Form Name:</b> {{ patientNameInResult }}</span></small
                        >
                    </div>
                    <div class="card card-body text-left col p-3 pt-0 mx-2 mx-sm-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-venus-mars fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.genderColumn }}</label>
                        <span v-if="patientToUse?.gender">{{ patientToUse?.gender }}</span>
                        <span v-else>
                            {{ GENERAL.notSet }}
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-row w-100">
                    <div class="card card-body text-left col p-3 pt-0 mx-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-birthday-cake fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.dobColumn }}</label>
                        <span>
                            {{ patientToUse?.dob }}
                        </span>
                    </div>
                    <div class="card card-body text-left p-3 pt-0 mx-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fa fa-arrow-up fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.heightColumn }}</label>
                        <span v-if="height.heightUnitValue">
                            <span v-if="height.heightUnitSecondaryValue">
                                {{ height.heightUnitSecondaryValue }}
                                {{ UNIT_SYSTEMS.imperial.heightUnitSecondary }}
                            </span>
                            <span> {{ height.heightUnitValue }} {{ heightUnit }} </span>
                        </span>
                        <span v-else>
                            {{ GENERAL.notSet }}
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-row w-100 w-lg-50 mx-auto mx-2">
                    <div class="card card-body text-left p-3 pt-0 mx-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-balance-scale fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.weightColumn }}</label>
                        <span v-if="weight.weightUnitValue"> {{ weight.weightUnitValue }} {{ weightUnit }} </span>
                        <span v-else>
                            {{ GENERAL.notSet }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row w-100 mx-auto mx-2">
                <div class="card card-body text-left p-3 pt-0 mx-2 my-2">
                    <span class="fa-stack fa-1x mb-2">
                        <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                        <i class="fas fa-user fa-stack-1x" style="color: #444444" />
                    </span>
                    <label class="mr-1 secondary-label small">Other info</label>
                    <span v-if="patientToUse?.profileData?.chronicIllnesses?.length">
                        <b> Chronic Illnesses: </b>{{ patientToUse?.profileData.chronicIllnesses?.join(', ') }}
                    </span>
                    <span v-if="patientToUse?.profileData?.respiratoryConditions?.length">
                        <b> Respiratory Conditions: </b>{{ patientToUse?.profileData.respiratoryConditions?.join(', ') }}
                    </span>
                    <span v-if="patientToUse?.profileData?.pregnancyStatus">
                        <b> Pregnancy: </b>{{ patientToUse?.profileData?.pregnancyStatus }} (Last period date:
                        {{ patientToUse?.profileData?.pregnancyDate }})
                    </span>
                    <span v-if="patientToUse?.profileData?.menopause">
                        <b> Menopause: </b>{{ patientToUse?.profileData?.menopause }} (Symptoms:
                        {{ patientToUse?.profileData?.menopauseSymptoms?.join(', ') || 'Not set' }})
                    </span>
                    <span v-if="patientToUse?.profileData?.perimenopause">
                        <b> Perimenopause: </b>{{ patientToUse?.profileData?.perimenopause }} (Symptoms:
                        {{ patientToUse?.profileData?.perimenopauseSymptoms?.join(', ') || 'Not set' }})
                    </span>
                    <span
                        v-if="
                            !patientToUse?.profileData?.chronicIllnesses?.length &&
                            !patientToUse?.profileData?.respiratoryConditions?.length &&
                            !patientToUse?.profileData?.pregnancyStatus &&
                            !patientToUse?.profileData?.menopause &&
                            !patientToUse?.profileData?.perimenopause
                        "
                    >
                        Nothing else mentioned by the user
                    </span>

                    <hr v-if="result?.order?.additionalInformation?.length" />
                    <small v-if="result?.order?.additionalInformation?.length">
                        <label class="mb-1 pb-0 font-weight-bold">Other notes/Information from the order</label>
                        <div
                            v-for="(info, index) in result?.order?.additionalInformation"
                            :key="index"
                            class="d-flex flex-row justify-content-between my-2"
                        >
                            <div class="w-50">
                                <span class="text-muted">(On {{ beautifyDate(info.date) }}):</span> <br />
                                <!-- preserve new lines etc-->
                                <div class="d-flex flex-row w-100 align-items-center">
                                    <div class="border p-2 w-100">
                                        <span style="white-space: pre-wrap; word-wrap: break-word">
                                            {{ info.data }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </small>
                </div>
            </div>
            <!-- <div
                v-if="showQuestionnaireAnswers && healthcareQuestionnaireFilteredVariables"
                class="d-flex flex-column w-100 w mx-auto flex-sm-row mt-3">
                <div class="card card-body p-3">
                    <p class="lead fw-normal mb-1 d-flex justify-content-md-between flex-column flex-md-row align-items-center align-items-md-start">
                        Health and Nutrition Questionnaire ({{ isTheQuestionnaireCurrent ? 'Current one' : 'When result came' }})
                    </p>
                    <p v-for="(question, index) in healthcareQuestionnaireFilteredVariables" :key="index" class="mb-2">
                        <small> {{ question.title }}: </small>
                        <small v-if="getUserResponse(question.id, userHealthcareQuestionnaire[question.id])" class="font-weight-bold">
                            {{ getUserResponse(question.id, userHealthcareQuestionnaire[question.id]) }}
                        </small>
                    </p>
                </div>
            </div> -->
        </article>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cmToInch, feetAndInchToInchOnly, inchToCm, inchToFeet, inchToRemainingInch, kgToLbs, lbsToKg } from '@/utils/utils';
import { healthcareQuestionnaireVariables, GENERAL, UNIT_SYSTEMS } from '../utils/constants';
import moment from 'moment';

export default {
    props: {
        order: {
            type: Object
        },
        result: {
            type: Object
        },
        userId: {
            type: String
        },
        showQuestionnaireAnswers: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            GENERAL,
            UNIT_SYSTEMS,
            userProfile: {},
            healthcareQuestionnaireVariables,
            height: {
                heightUnitValue: null,
                heightUnitSecondaryValue: null
            },
            weight: {
                weightUnitValue: null
            },
            unitSystem: ''
        };
    },
    computed: {
        ...mapGetters({
            allPatients: 'user/allPatients',
            allUsers: 'user/allUsers',
            loggedUser: 'user/currentUser'
        }),
        userHealthcareQuestionnaire() {
            return this.result?.PID
                ? this.result.PID?.healthcareQuestionnaire ||
                      (this.patientToUse.profileComplete || this.patientToUse.profilePartialCompleted
                          ? this.patientToUse.healthcareQuestionnaire
                          : null)
                : this.patientToUse.profileComplete || this.patientToUse.profilePartialCompleted
                ? this.patientToUse.healthcareQuestionnaire
                : null;
        },
        isTheQuestionnaireCurrent() {
            return !this.result?.PID?.healthcareQuestionnaire;
        },
        patientNameInResult() {
            return this.result?.result?.PID?.patientName
                ?.filter(Boolean)
                ?.filter((x) => x !== 'MASTER')
                .join(' ');
        },
        healthcareQuestionnaireFilteredVariables() {
            // eslint-disable-next-line no-confusing-arrow
            const toTake = _.map(this.healthcareQuestionnaireVariables, (v) =>
                this.userHealthcareQuestionnaire && this.userHealthcareQuestionnaire[v.id]?.value ? v.id : null
            ).filter((x) => x);

            if (!toTake || !toTake.length) return null;

            return _.pick(this.healthcareQuestionnaireVariables, toTake);
        },
        patientToUse() {
            if (this.userId) {
                return this.allPatients.find((p) => p.id === this.userId) || this.allUsers.find((u) => u.id === this.userId);
            }

            return this.result.user;
        },
        weightUnit() {
            if (this.loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.weightUnit;
            }

            return UNIT_SYSTEMS.metric.weightUnit;
        },
        heightUnit() {
            if (this.loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.heightUnitMain;
            }

            return UNIT_SYSTEMS.metric.heightUnitMain;
        }
    },
    async mounted() {
        this.userProfile = _.cloneDeep(this.patientToUse.toProfileJSON());

        if (this.loggedUser.unitSystem) {
            this.unitSystem = this.loggedUser.unitSystem;
        } else {
            this.unitSystem = UNIT_SYSTEMS.metric.id;
        }

        const heightCm = this.userProfile.height;
        const weightKg = this.userProfile.weight;

        if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
            const heightInch = cmToInch(heightCm);
            const remainingInch = inchToRemainingInch(Number(heightInch));
            const feet = inchToFeet(Number(heightInch));
            this.height.heightUnitValue = remainingInch;
            this.height.heightUnitSecondaryValue = feet;
            this.weight.weightUnitValue = kgToLbs(weightKg);
        } else {
            this.height.heightUnitValue = heightCm;
            this.weight.weightUnitValue = weightKg;
        }
    },
    methods: {
        getUserResponse(qId, response) {
            if (!qId || !response) return null;

            const q = this.healthcareQuestionnaireFilteredVariables[qId];

            if (q.options) {
                // eslint-disable-next-line no-confusing-arrow
                const r = _.find(q.options, (o) => (response?.value?.length ? response?.value.includes(o.value) : o.value === response.value));

                return response.other ? `${r?.label} - ${response.other}` : r?.label;
            }

            return response?.value || response?.other || null;
        },
        updateUnitSystem(system) {
            this.unitSystem = system;
            if (system === UNIT_SYSTEMS.imperial.id) {
                const inch = cmToInch(this.height.heightUnitValue);
                const remainingInch = inchToRemainingInch(Number(inch));
                this.height.heightUnitValue = remainingInch;
                const feet = inchToFeet(Number(inch));
                this.height.heightUnitSecondaryValue = feet;
                this.weight.weightUnitValue = kgToLbs(Number(this.weight.weightUnitValue));
            } else {
                const inchOnly = feetAndInchToInchOnly(Number(this.height.heightUnitSecondaryValue), Number(this.height.heightUnitValue));
                this.height.heightUnitValue = inchToCm(Number(inchOnly));
                this.height.heightUnitSecondaryValue = '';
                this.weight.weightUnitValue = lbsToKg(this.weight.weightUnitValue);
            }
        },
        beautifyDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
    }
};
</script>
