<template>
    <div
        v-if="resultItem && (user.isDoctor || user.isAdmin)"
        class="pt-5 px-2 fixed-bottom bg-light border-top border-secondary d-flex flex-column align-items-center justify-content-center"
    >
        <h6 v-if="resultItem.finished" class="mb-3"><i class="fas fa-exclamation-triangle" /> {{ DOCTOR_ACTIONS.reviewClosureMessage }}</h6>
        <b-button pill variant="primary" class="action-toggle" :disabled="loading" @click.self="actionAriaToggle">
            {{ expand ? DOCTOR_ACTIONS.collapseActions : DOCTOR_ACTIONS.expandActions }}
        </b-button>
        <b-collapse id="collapse-4" v-model="expand" class="w-100">
            <div class="px-2">
                <label for="textarea_res">{{ DOCTOR_ACTIONS.commentsLabel }}</label>
                <TextEditor
                    v-if="isTextEditable"
                    :initial-content="resultItem.comment"
                    :clear-content="clearComment"
                    @contentUpdated="(e) => (currentResult.comment = e)"
                />
                <TextEditorViewer v-else :content="resultItem.comment" />
            </div>
            <div class="d-flex flex-row mt-4 align-items-center justify-content-center actions-buttons">
                <div
                    v-b-tooltip.hover.top
                    :title="!isTextEditable ? '' : 'Do not forget to close (by pressing finish) the test when you have done the review.'"
                >
                    <loading-btn
                        :listen="'result/updateCommentOnResult'"
                        class="btn btn-link mb-3"
                        :disabled="!isTextEditable"
                        @click="updateComment()"
                    >
                        {{ BUTTONS.update }}
                    </loading-btn>
                </div>
                <div
                    v-b-tooltip.hover.top
                    :title="
                        currentResult.urgent
                            ? 'You cannot close this result because you ticked it as urgent. You need to wait for our staff to close it'
                            : !isTextEditable
                            ? ''
                            : 'Be aware that you\'ll not be able to change the comments afterwards and the result will be sent to the patient'
                    "
                >
                    <loading-btn
                        :listen="'result/finishResult'"
                        class="btn btn-primary mb-3"
                        :disabled="((user.isAdmin || user.isDoctor) && currentResult.urgent) || !isTextEditable"
                        @click="finishCurrentResult()"
                    >
                        {{ BUTTONS.finish }}
                    </loading-btn>
                </div>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { BUTTONS, DOCTOR_ACTIONS, STATUS_TYPES } from '@/utils/constants';
import TextEditor from './global/editor/text-editor';
import TextEditorViewer from './global/editor/text-editor-viewer';

export default {
    components: { TextEditor, TextEditorViewer },
    props: {
        expanded: { type: Boolean, default: false }
    },
    data() {
        return {
            BUTTONS,
            DOCTOR_ACTIONS,
            STATUS_TYPES,
            currentResult: {},
            expand: false,
            clearComment: false,
            editorOptions: {
                minHeight: '200px',
                language: 'en-US',
                useCommandShortcut: true,
                usageStatistics: true,
                hideModeSwitch: false,
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'image', 'link'],
                    ['scrollSync']
                ]
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            result: 'result/currentResult',
            allUserResults: 'result/allUserResults'
        }),
        loading() {
            return !!this.$store.state.loading['result/getAllResults'];
        },
        resultItem() {
            const resultId = this.$route.params.resultId || this.$route.query.resultId || this.$route.params.id;
            const orderId = this.$route.params.orderId || this.$route.query.orderId;

            return this.result
                ? _.cloneDeep(this.result)
                : _.find(this.allUserResults[this.patientIdToUse], (res) => res && (res.id === resultId || res.orderId === orderId));
        },
        isTextEditable() {
            return this.resultItem.doctor && !this.resultItem.finished && (this.resultItem.doctor.id === this.user.id || this.user.isAdmin);
        },
        patientIdToUse() {
            return this.$route.params.userId || this.$route.query.userId;
        }
    },
    watch: {
        expanded: {
            immediate: true,
            handler(val) {
                this.expand = val;
            }
        },
        result: {
            handler(val) {
                this.currentResult = _.cloneDeep(val);
            }
        }
    },
    async created() {
        if (!this.allUserResults[this.patientIdToUse]?.length) {
            await this.getAllResultsForAUser(this.patientIdToUse);
        }

        this.clearComment = false;
    },
    beforeDestroy() {
        this.$emit('toggleActions', false);
    },
    methods: {
        ...mapActions({
            getAllResultsForAUser: 'result/getAllResultsForAUser',
            updateCommentOnResult: 'result/updateCommentOnResult',
            finishResult: 'result/finishResult'
        }),
        actionAriaToggle() {
            this.expand = !this.expand;
            this.$emit('toggleActions', this.expand);
        },
        finishCurrentResult() {
            this.$confirm({
                message: 'Are you sure?',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.finishResult({
                                id: this.result.id,
                                comment: _.isEmpty(this.currentResult.comment)
                                    ? this.$sanitize(this.resultItem.comment)
                                    : this.$sanitize(this.currentResult.comment)
                            });
                            this.$notify({
                                type: STATUS_TYPES.success,
                                text: DOCTOR_ACTIONS.resultClosedSuccessMessage
                            });

                            this.clearComment = true;
                            await this.$nextTick();
                            this.$router.push('/results-management');
                        } catch (e) {
                            this.$notify({
                                type: STATUS_TYPES.error,
                                text: _.get(e.response, 'data.message', null) || e.message || DOCTOR_ACTIONS.orderNotClosedErrorMessage
                            });
                        }
                    }
                }
            });
        },
        async updateComment() {
            try {
                await this.updateCommentOnResult({
                    resultId: this.result.id,
                    comment: _.isEmpty(this.currentResult.comment)
                        ? this.$sanitize(this.resultItem.comment)
                        : this.$sanitize(this.currentResult.comment)
                });
                this.$notify({
                    type: STATUS_TYPES.success,
                    text: DOCTOR_ACTIONS.resultUpdatedSuccessMessage
                });
            } catch (e) {
                this.$notify({
                    type: STATUS_TYPES.error,
                    text: _.get(e.response, 'data.message', null) || e.message || DOCTOR_ACTIONS.resultUpdateErrorMessage
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.action-toggle {
    position: absolute;
    top: -18px;
    max-width: 190px;
}

@media screen and (max-width: 778px) {
    .actions-buttons {
        flex-direction: column !important;
    }
}
</style>
