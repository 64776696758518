import Vue from 'vue';
import { mapLoginActions } from '@frontegg/vue';
import routerInstance from '../router';

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useFronteggInstance = (router) => {
    if (instance) return instance;

    instance = new Vue({
        router,
        data() {
            return {
                ...this.mapAuthState(),
                ...this.mapTenantsState()
            };
        },
        watch: {
            authState: {
                async handler(val) {
                    const pendingLogin = !this.$router.history?.pending?.meta?.noBE && this.$router.history?.current?.path !== 'path';

                    if (pendingLogin && !this.$route.meta.noBE && !val.isAuthenticated && !val.isLoading) {
                        this.loginWithRedirect();
                    }
                }
            }
        },
        methods: {
            loginWithRedirect: mapLoginActions('loginWithRedirect')
        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const FronteggPlugin = {
    // eslint-disable-next-line no-shadow
    install(Vue, options) {
        Vue.prototype.$fronteggInstance = useFronteggInstance(routerInstance, options);
    }
};
