<script>
export default {
    inheritAttrs: false,
    props: {
        listen: {
            type: [Array, String],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideWhenLoading: {
            type: Boolean,
            default: false
        },
        onlyWhenLoading: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: 'button'
        },
        loadingClass: {
            type: String,
            default: ''
        },
        forceLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        loading() {
            if (this.listen instanceof Array) {
                return this.listen.some((listenTo) => this.$store.state.loading[listenTo]);
            }

            return this.$store.state.loading[this.listen] || this.forceLoading;
        }
    },
    methods: {
        clicked(e) {
            this.$root.$emit('bv::hide::tooltip', this.$attrs.id);
            this.$emit('click', e);
        }
    },
    render(h) {
        const children = [];
        const vm = this;

        if (this.onlyWhenLoading && !this.loading) {
            return null;
        }

        if (this.loading) {
            if (this.$slots.loader) {
                children.push(this.$slots.loader);
            } else {
                // TODO: If default slot starts with <i> tag its only going to be duplicated by this - no loader will display
                // TODO: Its probably due to this restriction: https://vuejs.org/v2/guide/render-function.html#Constraints
                // <font-awesome-icon icon="fa fa-arrow-left" class="fa-xs pr-1" />
                children.push([
                    h('font-awesome-icon', {
                        attrs: {
                            class: 'mr-2',
                            icon: 'fa-solid fa-spinner',
                            spin: true
                        }
                    })
                ]);

                children.push([
                    h('span', {
                        attrs: {
                            class: 'mr-2'
                        }
                    })
                ]);
            }
        }

        if (!this.hideWhenLoading || !this.loading || (this.onlyWhenLoading && this.loading)) {
            children.push(this.$slots.default);
        }
        return h(
            this.tag,
            {
                attrs: {
                    ...this.$attrs,
                    class: `loading-btn ${this.$vnode.data.staticClass} `
                        .concat(this.loading ? this.loadingClass : '')
                        .concat(this.loading || this.disabled ? ' disabled' : ''),
                    disabled: this.loading || this.disabled
                },
                on: {
                    click(e) {
                        if (!vm.disabled) {
                            vm.clicked(e);
                        }
                    }
                }
            },
            children
        );
    }
};
</script>
