/* eslint-disable no-unused-vars */
import { constructQueryParams } from '@/utils/utils';
import api from './api';

export default {
    async getDeviceMetricSummaryForAPatient(userId, { date, filter, unitSystem, timeUnit }) {
        const stringFilter = filter ? JSON.stringify(filter) : null;

        const queryParams = constructQueryParams({
            userId,
            date,
            filter: stringFilter,
            unitSystem,
            timeUnit
        });

        const response = await api.get(`patient/metrics/general/summary?${queryParams}`);

        return response;
    }
};
