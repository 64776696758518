/* eslint-disable no-unused-vars */
import api from './api';

export default {
    async updateTenantInfoFromMetadata(payload) {
        const response = await api.post('tenant/update/metadata/info', payload);
        return response;
    },
    async updateTenantPregnancyOutcomeSystemStatus(payload) {
        const response = await api.post('tenant/update/metadata/pregnancy-outcome-system', payload);
        return response;
    }
};
