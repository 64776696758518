<template>
    <div v-if="metrics.length">
        <h6 class="d-flex flex-column align-items-start">
            <label class="mb-1 pl-3">Device metrics</label>
        </h6>
        <VueSlickCarousel v-bind="sliderSettings" class="m-2">
            <div v-for="item in metrics" :key="item.key" class="same-height p-2">
                <div class="card w-100">
                    <div class="card-inner border-0 d-flex flex-column align-items-center justify-content-center">
                        <div class="d-flex flex-column align-items-center justify-content-center text-center w-100">
                            <small class="font-weight-bold"
                                >{{ item.metric.displayName }}
                                <span v-if="item.metric.description" v-b-tooltip.hover.top class="ml-1" :title="item.metric.description">
                                    <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                            </small>
                            <div class="d-flex align-items-center mt-1">
                                <h5 class="m-0 p-0">
                                    {{ item.metric.formattedValue }}
                                </h5>
                                <!-- <span
                                v-if="item.trend"
                                v-b-tooltip.hover.top
                                :title="`${item.label} - Taking into consideration the last
                                                        ${item.trend.percentageTakenIntoConsideration * 100}% of the data points,
                                                         the metric is ${getTrendLabel(item.trend)}`">
                                <b-badge pill variant="light" class="mx-1">
                                    <b-icon variant="secondary" :icon="getTrendIcon(item.trend)" />
                                </b-badge>
                            </span> -->
                            </div>
                            <div class="d-flex flex-column mt-2">
                                <small>Avg. value between:</small>
                                <div class="d-flex align-items-center">
                                    <small v-if="item.startDate" class="mr-1">{{ formatDate(item.startDate) }}</small>
                                    -
                                    <small v-if="item.endDate" class="ml-1">{{ formatDate(item.endDate) }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
    </div>
    <div v-else class="card card-body text-center p-3 mx-2 my-2">
        <div>No device metrics available {{ date ? `up to ${formatDate(date)}` : '' }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import { wearableDataInfoIcons } from '@/utils/constants';
import moment from 'moment';
import { getTrendIcon, getTrendLabel } from '../utils/utils';

export default {
    components: {
        VueSlickCarousel
    },
    props: {
        metrics: {
            type: Array,
            default: () => []
        },
        date: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            wearableDataInfoIcons,
            sliderSettings: {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 6,
                slidesToScroll: 1,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 9000,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500
                        }
                    },
                    {
                        breakpoint: 1670,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500
                        }
                    },
                    {
                        breakpoint: 1330,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500
                        }
                    },
                    {
                        breakpoint: 1030,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500,
                            rows: 2
                        }
                    },
                    {
                        breakpoint: 830,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500,
                            rows: 2
                        }
                    },
                    {
                        breakpoint: 630,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: false,
                            speed: 500,
                            rows: 2
                        }
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        })
    },
    created() {
        this.sliderSettings.slidesToShow = this.metrics?.length < 5 ? this.metrics?.length : 5;
        this.sliderSettings.responsive = _.map(this.sliderSettings.responsive, (r) => {
            r.settings.slidesToShow = this.metrics?.length < r.settings.slidesToShow ? this.metrics?.length : r.settings.slidesToShow;
            return r;
        });
    },
    methods: {
        getTrendIcon,
        getTrendLabel,
        formatDate(date) {
            return moment(date).format('MMM D, YYYY');
        }
    }
};
</script>

<style lang="scss">
.same-height {
    display: flex;
    flex-direction: column;
}
.same-height .card {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 132px !important;
}
.same-height .card .d-flex {
    flex: 1;
}
</style>
