/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { getDisplayDate, getFirstAndLastNameFromFullName } from '@/utils/utils';
import { getInstance } from '@/utils/auth.instance';
import { OrganisationPermissions, Roles, SubscriptionEntitlementName } from '@/utils/constants';
import Model from './model';

const defaultPhoto = require('../../assets/img/profile-picture.jpg');

const useragentInfo = {
    agent: window.navigator.userAgent,
    standalone: window.navigator.standalone,
    platform: navigator.platform,
    maxTouchPoints: navigator.maxTouchPoints
};
export default class UserModel extends Model {
    get loggedIn() {
        return !!this.id || !!this.email;
    }

    get age() {
        const toUse = moment(this.dob, 'MMMM DD YYYY');
        return moment().diff(toUse, 'years');
    }

    get pregnancyOutcomeStatus() {
        return {
            status: this.attributes.pregnancyOutcomeStatus?.status,
            lastUpdatedDate: new Date(this.attributes.pregnancyOutcomeStatus?.lastUpdatedDate)
        };
    }

    get isPatient() {
        return this.attributes.isPatient;
    }

    get name() {
        return this.attributes.profileData?.name || this.attributes.name;
    }

    get concatenatedName() {
        const fullNameJSON = getFirstAndLastNameFromFullName(this.name);

        return `${fullNameJSON.firstName}_${fullNameJSON.lastName}`?.replace(/ /g, '_');
    }

    get email() {
        return this.attributes.profileData?.email || this.attributes.email;
    }

    get weight() {
        return this.attributes.profileData?.weight || this.attributes.weight;
    }

    get height() {
        return this.attributes.profileData?.height || this.attributes.height;
    }

    get profilePictureUrl() {
        return this.attributes.profileData?.profilePictureUrl || this.attributes.profilePictureUrl || `${window.location.origin}${defaultPhoto}`;
    }

    get gender() {
        return this.attributes.profileData?.gender || this.attributes.gender;
    }

    get unitSystem() {
        return this.attributes.profileData?.unitSystem || this.attributes.unitSystem;
    }

    get lastLoginDate() {
        return this.attributes.profileData?.lastLoginDate || this.attributes.lastLoginDate;
    }

    get isDemo() {
        return this.attributes.demo;
    }

    get useDoctorLanguage() {
        return this.isDoctor && !this.isAdmin;
    }

    get isAdmin() {
        return this.attributes.isAdmin;
    }

    get isDoctor() {
        return this.attributes.isDoctor;
    }

    get isSuperAdmin() {
        return this.attributes.isSuperAdmin;
    }

    get dob() {
        const dob = this.attributes.profileData?.dob || this.attributes.dob;
        return getDisplayDate(dob, 'MMM DD YYYY');
    }

    get accessGranted() {
        return this.attributes.accessGranted;
    }

    get isProspect() {
        return this.attributes.isProspect;
    }

    get readOnly() {
        return this.attributes.readOnly;
    }

    hasRequirement() {
        return true;
    }

    hasAnyPermission(permissions) {
        // can be something like "permission1 permission2 permission3" (space between permissions)
        // the space is an OR - if the user has any of the permissions, it will return true
        // can be also a single permission
        const tenantInfo = getInstance()?.tenantsState?.tenants[0];
        if (!tenantInfo) return false;

        const metadata = JSON.parse(tenantInfo.metadata);
        if (!metadata?.customPermissions || !metadata?.customPermissions?.length) return false;

        const permissionArray = permissions?.split(' ');

        // if we have set some custom hidden permissions on the user itself (because user might be in b2c but another country than UK -> no access to blood things)
        const permissionsToCheck = this.attributes.metadata?.hidePermissions
            ? metadata?.customPermissions.filter((p) => !this.attributes.metadata?.hidePermissions.includes(p))
            : metadata?.customPermissions;

        return permissionArray.some((item) => permissionsToCheck.includes(OrganisationPermissions[item.toUpperCase()] || item)) || false;
    }

    hasAnyEntitlement(entitlements) {
        // can be something like "permission1 permission2 permission3" (space between permissions)
        // the space is an OR - if the user has any of the permissions, it will return true
        // can be also a single permission
        const entitlementsInfo = this.attributes.entitlements;
        if (!entitlementsInfo) return false;

        const entitlementsArray = entitlements.split(' ');

        return entitlementsArray.some((item) => entitlementsInfo[SubscriptionEntitlementName[item] || item]) || false;
    }

    canAccess(level) {
        const roles = this.attributes.roles || [{ key: Roles.VIEW }];

        const askedLevelArray = level.split(' ');

        return askedLevelArray.some((item) => roles.find((r) => (r.key ?? r) === (Roles[item.toUpperCase()] || item)));
    }

    get tenant() {
        const tenantInfo = getInstance()?.tenantsState?.tenants[0] || this.attributes.currentTenant;
        if (!tenantInfo) return false;

        let metadata = _.isString(tenantInfo.metadata) ? JSON.parse(tenantInfo.metadata) : tenantInfo.metadata;
        metadata = metadata ? { ...metadata, ...this.attributes.currentTenant.metadata } : this.attributes.currentTenant?.metadata;

        return { ...tenantInfo, ...this.attributes.currentTenant, metadata };
    }

    get getMobileType() {
        if (/android/i.test(useragentInfo.agent)) {
            return 'ANDROID';
        }

        if (/iPad|iPhone|iPod/.test(useragentInfo.agent) || (useragentInfo.platform === 'MacIntel' && useragentInfo.maxTouchPoints > 1)) {
            return 'APPLE';
        }

        return null;
    }

    toJSON() {
        const jsonObj = super.toJSON();
        delete jsonObj.age;
        delete jsonObj.isRegistered;
        delete jsonObj.accessGranted;
        delete jsonObj.askForSubscription;
        delete jsonObj.healthcareQuestionnaire;

        return {
            ...jsonObj,
            profileData: this.attributes.profileData,
            isPatient: this.attributes.isPatient
        };
    }

    toProfileJSON() {
        const jsonObj = super.toJSON();

        const basicInfo = {
            name: jsonObj.profileData?.name || jsonObj.name,
            email: jsonObj?.email,
            profilePictureUrl: this.profilePictureUrl == defaultPhoto ? this.attributes.profileData?.profilePictureUrl : this.profilePictureUrl,
            gender: jsonObj.profileData?.gender,
            unitSystem: jsonObj.profileData?.unitSystem || jsonObj.unitSystem,
            lastLoginDate: jsonObj?.lastLoginDate
        };

        if (this.isDoctor) {
            return {
                ...basicInfo,
                specialty: jsonObj.profileData.specialty
            };
        }

        if (this.isPatient) {
            return {
                ...basicInfo,
                weight: jsonObj.profileData?.weight || jsonObj.weight,
                height: jsonObj.profileData?.height || jsonObj.height,
                defaultFilters: jsonObj.defaultFilters,
                dob: jsonObj.profileData?.dob,
                profileData: jsonObj.profileData
            };
        }

        return basicInfo;
    }
}
