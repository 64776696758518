/* eslint-disable import/no-cycle */
import Vue from 'vue';
import api from '../api/metrics.api';
import MetricsModel from '../models/metrics.model';

export default {
    namespaced: true,
    state: {
        patientDeviceMetricSummary: {}
    },
    getters: {
        patientDeviceMetricSummary(state) {
            return state.patientDeviceMetricSummary;
        }
    },
    mutations: {
        setDeviceMetrics(state, { userId, metrics }) {
            Vue.set(state.patientDeviceMetricSummary, userId, metrics);
        }
    },
    actions: {
        async getDeviceMetricSummaryForAPatient({ commit, state }, { userId, payload = {} }) {
            commit('activateLoading', 'metrics/getAllWearableDataForAUser', { root: true });
            try {
                if (!userId) {
                    throw new Error('userId is required');
                }

                let { filter, timeUnit } = payload;

                if (!filter) {
                    filter = {
                        type: 'custom',
                        value: 'last30Days'
                    };

                    payload.filter = filter;
                }

                if (!timeUnit) {
                    timeUnit = 'month';
                    payload.timeUnit = timeUnit;
                }

                if (state.patientDeviceMetricSummary[userId]) {
                    return new MetricsModel(state.patientDeviceMetricSummary[userId]);
                }

                const metrics = await api.getDeviceMetricSummaryForAPatient(userId, payload);

                commit('setDeviceMetrics', { userId, metrics });

                return metrics;
            } finally {
                commit('deactivateLoading', 'metrics/getAllWearableDataForAUser', { root: true });
            }
        }
    }
};
