/* eslint-disable no-self-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';

export function uuid(length = 8) {
    let now = new Date().getTime();
    const string = [];

    for (let i = 1; i <= length; i++) {
        // eslint-disable-next-line no-bitwise
        const r = (now + Math.random() * 16) % 16 | 0;

        now = Math.floor(now / 16);

        // eslint-disable-next-line no-bitwise
        string.push((Math.ceil(Math.random() * 1000) % 2 === 0 ? r : (r & 0x3) | 0x8).toString(16));

        if (i % 4 === 0 && i !== length) {
            string.push('-');
        }
    }

    return string.join('');
}

export const formatValue = (
    val,
    type = 'number',
    unit = null,
    minimumDecimals = 2,
    maximumDecimals = 4,
    multiplyFactor = 1,
    convertToUnit = null
) => {
    if (!_.isNumber(val)) return 0;

    let num = parseFloat(_.replace(val, /,/g, '')) * multiplyFactor;

    if (!_.isNumber(num)) return 0;

    // convert;
    switch (type) {
        case 'number':
            break;
        case 'percentage':
            break;
        case 'period':
            switch (convertToUnit) {
                case 'h':
                    unit === 's' ? (num = num / 60 / 60) : unit === 'm' ? (num /= 60) : (num = num);
                    break;
                case 'm':
                    unit === 's' ? (num /= 60) : unit === 'h' ? (num *= 60) : (num = num);
                    break;
                default:
                    break;
            }
            unit = convertToUnit || unit;
            break;
        default:
            break;
    }
    // if (num > 100000) return summarizeNum(val);

    let decimals = getDecimals(val, minimumDecimals, maximumDecimals);

    const checkNotOnlyZeros = num
        .toFixed(decimals)
        .split('')
        .reverse()
        .join('')
        .substring(0, decimals)
        .split('')
        .some((x) => x != '0');

    decimals = checkNotOnlyZeros ? decimals : checkNotOnlyZeros;

    const valueWithDecimalsNeeded = num.toFixed(decimals);

    const numberWithoutUnit = numberWithCommas(valueWithDecimalsNeeded);
    const numberWithUnit = `${numberWithoutUnit}${unit ? ` ${unit}` : ''}`;

    switch (type) {
        case 'number':
            return numberWithUnit;
        case 'percentage':
            return `${numberWithoutUnit}%`;
        case 'period':
            return `${valueWithDecimalsNeeded} ${unit}`;

        default:
            return numberWithUnit;
    }
};

export const formatNumberWithDecimals = (val, minimumDecimals = 2, maximumDecimals = 4, multiplyFactor = 1, type, unit, convertToUnit) => {
    if (!_.isNumber(val)) return 0;

    let num = parseFloat(_.replace(val, /,/g, '')) * multiplyFactor;
    if (!_.isNumber(num)) return 0;
    // if (num > 100000) return summarizeNum(val);

    switch (type) {
        case 'number':
            break;
        case 'percentage':
            break;
        case 'period':
            switch (convertToUnit) {
                case 'h':
                    unit === 's' ? (num = num / 60 / 60) : unit === 'm' ? (num /= 60) : (num = num);
                    break;
                case 'm':
                    unit === 's' ? (num /= 60) : unit === 'h' ? (num *= 60) : (num = num);
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    const decimals = getDecimals(val, minimumDecimals, maximumDecimals);
    let valueWithDecimalsNeeded = num.toFixed(decimals);
    const checkNotOnlyZeros = valueWithDecimalsNeeded
        .split('')
        .reverse()
        .join('')
        .substring(0, decimals)
        .split('')
        .some((x) => x != '0');
    valueWithDecimalsNeeded = checkNotOnlyZeros ? valueWithDecimalsNeeded : num.toFixed();

    return Number(valueWithDecimalsNeeded);
};

function numberWithCommas(x) {
    return new Intl.NumberFormat().format(x);
}

export const getDecimals = (val, minimumDecimals = 2, maximumDecimals = 4) => {
    if (!val) return 0;

    const num = parseFloat(_.replace(val, /,/g, ''));
    if (!num) return 0;

    const stringValue = num.toString();
    let decimalsNeeded = 0;

    if (num < 1 && stringValue.includes('.')) {
        const valueForDecimals = Number(stringValue.replace(stringValue.substr(0, stringValue.indexOf('.')), '0'));
        const decimals = -Math.floor(Math.log10(valueForDecimals) + 1) + 2; // count decimals needed
        decimalsNeeded = !decimals || decimals === Infinity ? null : decimals;
    }

    decimalsNeeded = decimalsNeeded > maximumDecimals ? maximumDecimals : decimalsNeeded;

    return decimalsNeeded > minimumDecimals ? decimalsNeeded : minimumDecimals;
};

export const summarizeNum = (val) => {
    let num = parseFloat(_.replace(val, /,/g, ''));

    if (!num) return 0;
    const negative = num < 0;

    let suffix = '';

    num = Math.abs(num);

    if (num > 1000) {
        suffix = 'K';
        num /= 1000;
    }
    if (num > 1000) {
        suffix = 'M';
        num /= 1000;
    }
    if (num > 1000) {
        suffix = 'B';
        num /= 1000;
    }

    if (num > 1000) {
        suffix = 'T';
        num /= 1000;
    }

    if (negative) {
        return `-${_.round(num, 2)}${suffix}`.trim();
    }

    return `${_.round(num, 2)}${suffix}`.trim();
};

export function createSimpleFunctional(c, el = 'div', name) {
    return Vue.extend({
        name: name || c.replace(/__/g, '-'),
        functional: true,
        render(h, { data, children }) {
            data.staticClass = `${c} ${data.staticClass || ''}`.trim();

            return h(el, data, children);
        }
    });
}

export function labelBasedOnDateType(dateFilter) {
    switch (dateFilter.type) {
        case 'all-time':
            return 'All Time';
        case 'last-30-days':
            return 'Last 30 Days';
        case 'last-2-weeks':
            return 'Last 2 Weeks';
        case 'last-month':
            return 'Last Full Month';
        case 'last-3-months':
            return 'Last 3 Months';
        case 'custom':
            return 'Custom';
        default:
            return 'All Time';
    }
}

export function getDisplayDate(date, format = 'DD/MM/YYYY HH:mm') {
    return date ? moment(new Date(date)).format(format) : null;
}

export function getTrendIcon(trend) {
    switch (trend.type) {
        case 'up':
            return 'chevron-double-up';
        case 'down':
            return 'chevron-double-down';
        case 'short-up':
            return 'chevron-up';
        case 'short-down':
            return 'chevron-down';
        default:
            return 'chevron-contract';
    }
}

export function getTrendLabel(trend) {
    switch (trend.type) {
        case 'up':
            return 'trending up';
        case 'down':
            return 'trending down';
        case 'short-up':
            return 'slowly trending up';
        case 'short-down':
            return 'slowly trending down';
        default:
            return 'linear';
    }
}

export const inchToFeet = (heightInInchOnly) => {
    return Math.floor(heightInInchOnly / 12);
};

export const inchToRemainingInch = (heightInInchOnly) => {
    const remainingInches = heightInInchOnly % 12;
    return +(Math.round(remainingInches * 100) / 100).toFixed(1);
};

export const feetAndInchToInchOnly = (feet, inch) => {
    return feet * 12 + inch;
};

export const inchToCm = (inch) => {
    return +Math.round(inch * 2.54);
};

export const cmToInch = (cm) => {
    return +(Math.round((cm / 2.54) * 100) / 100).toFixed(1);
};

export const kgToLbs = (kg) => {
    return +(Math.round(kg * 2.2 * 100) / 100).toFixed(1);
};

export const lbsToKg = (lbs) => {
    return +Math.round(lbs / 2.2);
};

export const filterArray = (data, searchText) => {
    return data.filter((x) => x.text.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
};

export const sortArrayByDate = (array) => {
    return array.sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const commonElements = (arrayOfArrays) => {
    if (arrayOfArrays.length === 0) {
        return [];
    }

    const referenceArray = arrayOfArrays[0];
    const commonArray = referenceArray.filter((item) => arrayOfArrays.every((array) => array.includes(item)));

    return commonArray;
};

export const countItemsWithCondition = (arr, conditionCallback) => {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
        if (conditionCallback(arr[i])) {
            count++;
        }
    }

    return count;
};

export const formatRoute = (name) => {
    return `/${name}`;
};

export function constructQueryParams(params) {
    const queryParams = [];

    for (const [key, value] of Object.entries(params)) {
        let finalValue = value;
        // Check if value is not null or undefined
        if (finalValue !== null && finalValue !== undefined && (_.isArray(finalValue) ? !!finalValue.length : true)) {
            if (_.isArray(finalValue)) {
                finalValue = finalValue.join(',');
            }

            queryParams.push(`${key}=${encodeURIComponent(finalValue)}`);
        }
    }

    return queryParams.join('&');
}

export function getFirstAndLastNameFromFullName(fullName = '') {
    const result = /^(?<first>[\w-. ]+?)(?:\s+(?<last>\w*))?$/g.exec(fullName);

    return {
        firstName: (result.groups.first || '').trim() || null,
        lastName: (result.groups.last || '').trim() || null
    };
}
