<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div>Redirecting...</div>
    </div>
</template>

<script>
export default {
    name: 'Redirect',
    mounted() {
        const queryParamFor = this.$route.query.for;
        let url = '';

        switch (queryParamFor) {
            case 'kit-sub':
                url = 'subscription/blood-kit';
                break;
            case 'profile-sub':
                url = 'subscription/profile';
                break;
            case 'profile':
                url = 'profile';
                break;
            case 'general':
                url = 'general';
                break;
            default:
                url = 'profile';
                break;
        }

        const customSchemeUrl = `lola://${url}`;
        const fallbackUrl = `${window.location.origin}/`;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
        const isWebView = /(wv)|(!Safari)/i.test(window.navigator.userAgent); // Simplistic WebView detection

        this.redirectToCustomScheme(customSchemeUrl, fallbackUrl, isMobile || isWebView);
    },
    methods: {
        redirectToCustomScheme(customSchemeUrl, fallbackUrl, isFromMobile = false) {
            // Attempt to redirect to the custom scheme URL (deep link)
            window.location.replace(customSchemeUrl);

            if (isFromMobile) {
                // Use a timeout to check if the app is not opened
                const timeout = setTimeout(() => {
                    // This will execute if the app hasn't opened after 2 seconds
                    window.location.replace(fallbackUrl);
                }, 2000);

                // Clear the timeout if the document gets hidden (app is opened)
                document.addEventListener(
                    'visibilitychange',
                    () => {
                        if (document.visibilityState === 'hidden') {
                            clearTimeout(timeout);
                        }
                    },
                    { once: true }
                );
            } else {
                // If the user is not on mobile, redirect to the fallback URL immediately
                window.location.replace(fallbackUrl);
            }
        }
    }
};
</script>
