/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { isAuthenticatedGuard } from '@frontegg/vue';
import { formatRoute } from '@/utils/utils';
import { Routes } from '@/utils/constants';
import TenantProfileInfo from '@/components/tenant-profile-info.vue';
import UserProfileInfo from '@/components/user-profile-info.vue';
import Home from '../views/Home';
import Profile from '../views/Profile';
import Patients from '../views/Patients';
import Onboarding from '../views/Onboarding';
import UserData from '../views/UserDataInfo.vue';
import OrderManagement from '../views/OrderManagement';
import Result from '../views/Result';
import Logout from '../components/base/logout';
import NotFound404 from '../views/NotFound404';
import Redirect from '../views/Redirect';
import store from '../store';
import ResultsManagement from '../views/ResultsManagement';

Vue.use(VueRouter);

const filterTenantPermissions = (to, meta) => {
    let tenantPermissions = to.meta?.tenantPermissions || [];

    const { queries } = to.meta?.generalSubscriptionNotNeededCases;
    const { notNeeded } =
        queries.find((queryInfo) => {
            const { query, params } = queryInfo;
            const queryKeys = Object.keys(query || {});
            const paramsKeys = Object.keys(params || {});

            if (!queryKeys.length && !paramsKeys.length) return false;

            const queryOk = queryKeys.every((key) => (to.query[key] ? to.query[key] === query[key] : true));
            const paramsOk = paramsKeys.every((key) => (to.params[key] ? to.params[key] === params[key] : true));

            return queryOk && paramsOk;
        }) || {};

    if (notNeeded) {
        tenantPermissions = tenantPermissions.filter((permission) => !notNeeded.includes(permission));
    }

    return tenantPermissions;
};

function createRoute(path, name, component, meta, children) {
    const route = {
        path: path,
        name: name,
        component: component,
        meta: {
            accessBasedOnUserProperty: meta?.accessBasedOnUserProperty,
            auth: meta?.auth, // Auth requirements (e.g., ['isPatient', 'isDoctor'])
            showDoctorActions: meta?.showDoctorActions,
            //tenantPermissions: meta?.tenantPermissions, // Tenant-specific permissions,
            //generalSubscriptionNotNeededCases: meta?.generalSubscriptionNotNeededCases,
            noBE: meta?.noBE
        }
    };

    if (children) {
        route.children = children;
    }

    return route;
}
const routes = [
    {
        path: '/',
        redirect: formatRoute(Routes.HOME)
    },
    createRoute(formatRoute(Routes.HOME), Routes.HOME, Home, {
        auth: ['isDoctor', 'isAdmin', 'isPatient'],
        enableNavigation: false
    }),
    createRoute(formatRoute(Routes.RESULTS_MANAGEMENT), Routes.RESULTS_MANAGEMENT, ResultsManagement, {
        auth: ['isDoctor', 'isAdmin'],
        enableNavigation: false
    }),
    createRoute('/users/:userId', 'user-info', UserData, {
        auth: ['isAdmin', 'isDoctor'],
        enableNavigation: true
    }),
    createRoute(formatRoute(Routes.PATIENTS), Routes.PATIENTS, Patients, {
        auth: ['isAdmin', 'isDoctor'],
        enableNavigation: true
    }),
    createRoute(formatRoute(Routes.ORDER_MANAGEMENT), Routes.ORDER_MANAGEMENT, OrderManagement, {
        auth: ['isAdmin'],
        enableNavigation: false
    }),
    createRoute('/result/:id', 'Result', Result, {
        auth: ['isAdmin', 'isDoctor'],
        enableNavigation: true,
        showDoctorActions: true
    }),
    createRoute(
        formatRoute(Routes.PROFILE),
        Routes.PROFILE,
        Profile,
        {
            auth: ['isAdmin', 'isDoctor']
        },
        [
            {
                path: '',
                name: 'me',
                component: UserProfileInfo
            },
            {
                path: 'tenant',
                name: 'tenant',
                component: TenantProfileInfo
            }
        ]
    ),
    createRoute(formatRoute(Routes.LOGOUT), Routes.LOGOUT, Logout),
    createRoute('/404-not-found', '404 Not Found', NotFound404, {
        noBE: true
    }),
    createRoute('/redirect', 'Lola - App - Redirect', Redirect, {
        noBE: true
    })
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        const element = document.querySelector('.vm-content');
        const top = element ? element.offsetTop : 0;
        if (element) {
            element.scrollTo({
                top,
                behavior: 'smooth'
            });
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.fullPath !== from.fullPath && !to.meta.noBE) {
        const isAuthenticated = store.state.auth.isAuthenticated;

        if (!isAuthenticated) {
            store.watch(
                (state) => state.auth.isAuthenticated,
                (status) => {
                    return status ? isAuthenticatedGuard(to, from, next) : null;
                }
            );
        } else {
            return isAuthenticatedGuard(to, from, next);
        }
    }

    next((error) => {
        if (error) {
            console.error('Navigation Error:', error);
        }
    });
});

router.beforeResolve((to, from, next) => {
    if (!to.meta.noBE && ((to.fullPath !== from.fullPath && !to.hash.includes('admin-box')) || (!from.name && !to.hash.includes('admin-box')))) {
        const currentUser = store.state.user.user;

        if (!currentUser?.id) {
            store.watch(
                (state) => state.user.user,
                (_user) => {
                    const toSend = store.getters['user/currentUser'];
                    return generalAccessGuard(to, next, toSend);
                }
            );
        } else {
            const toSend = store.getters['user/currentUser'];
            return generalAccessGuard(to, next, toSend);
        }
    }

    if (to.meta.noBE) {
        next((error) => {
            if (error) {
                console.error('Navigation Error:', error);
            }
        });
    }
});

const generalAccessGuard = (to, next, user) => {
    const currentUser = user;

    let hasAccess = to.meta && to.meta.auth ? to.meta.auth.some((role) => currentUser[role]) : true;
    hasAccess = hasAccess
        ? to.meta.accessBasedOnUserProperty
            ? !to.meta.accessBasedOnUserProperty.find((prop) => !currentUser[prop])
            : hasAccess
        : hasAccess;

    if (!hasAccess) {
        return next('/');
    }

    if (to.meta.tenantPermissions) {
        let tenantPermissions = to.meta.tenantPermissions;

        if (to.meta.generalSubscriptionNotNeededCases) {
            tenantPermissions = filterTenantPermissions(to, to.meta);
        }

        const hasPermission = !tenantPermissions.map((permission) => !!currentUser.hasAnyPermission(permission)).some((x) => x === false);

        if (!hasPermission) return next('/profile');
    }

    return next();
};

export default router;
