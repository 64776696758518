import moment from 'moment';
import Model from './model';
import UserModel from './user.model';
import OrderModel from './order.model';

export default class ResultModel extends Model {
    get displayFinishedDate() {
        return this.attributes.finishedDate ? moment(this.attributes.finishedDate).local().format('DD MMM yyyy HH:mm') : null;
    }

    get displayDateAdded() {
        return this.attributes.dateAdded ? moment(this.attributes.dateAdded).local().format('DD MMM yyyy HH:mm') : null;
    }

    get displayDateUpdated() {
        return this.dateUpdated ? moment(this.dateUpdated).local().format('DD MMM yyyy HH:mm') : null;
    }

    get dateForMetricsFetch() {
        const date = this.attributes.finishedDate || this.attributes.dateAdded || this.dateUpdated;

        // if it's older than 5 days compared with today, then we return the date, otherwise we return today
        return date ? (moment(date).isBefore(moment().subtract(5, 'days')) ? date : null) : null;
    }

    get user() {
        return this.attributes.user ? new UserModel(this.attributes.user) : null;
    }

    get order() {
        return this.attributes.order ? new OrderModel(this.attributes.order) : null;
    }

    get patientNameInResult() {
        return this.attributes.result?.PID?.patientName
            ?.filter(Boolean)
            ?.filter((x) => x !== 'MASTER')
            .join(' ');
    }

    get hasInvalidMarkers() {
        return this.attributes.hasInvalidMarkers || this.invalidMarkersCount > 0;
    }

    get invalidMarkersCount() {
        return _.reduce(this.attributes.result.OBX, (acc, cat) => acc + cat.markers.filter((m) => m.invalid).length, 0);
    }
}
