<template>
    <div class="home">
        <PatientManagement v-can-access.role="'admin doctor'" />
    </div>
</template>

<script>
import PatientManagement from './PatientManagement';

export default {
    components: { PatientManagement }
};
</script>

<style lang="scss">
@import '../styles/home.scss';
</style>
