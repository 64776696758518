<template>
    <div v-can-access.role="'admin'">
        <div class="row justify-content-md-center flex-column mx-0">
            <div class="form-group mt-4">
                <h4 class="text__page-header mt-2">
                    {{ ORDER_MANAGEMENT.headline }}
                </h4>
                <OrdersTable
                    :orders="orderList"
                    @viewUser="viewUserInfo"
                    @viewResult="viewResult"
                    @pageChange="onPageChange"
                    @search="onSearchTerm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrdersTable from '@/components/orders-table';
import { ORDER_MANAGEMENT } from '@/utils/constants';
import _ from 'lodash';

export default {
    components: { OrdersTable },
    data() {
        return {
            ORDER_MANAGEMENT,
            ...this.mapAuthState()
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            allOrders: 'order/allOrders',
            status: 'order/status',
            page: 'order/page',
            pageSize: 'order/pageSize',
            outliers: 'order/outliers'
        }),
        orderList() {
            return _.values(this.allOrders);
        }
    },
    async created() {
        await this.getAllOrders();
    },
    methods: {
        ...mapActions({
            getAllOrders: 'order/getAllOrders'
        }),
        viewUserInfo(userId) {
            this.$router.push({ name: 'user-info', params: { userId } });
        },
        viewResult(orderId) {
            this.$router.push({ name: 'Result', params: { id: orderId }, query: { idType: 'order' } });
        },
        async onPageChange({ page, status, queryString, outliers }) {
            await this.getAllOrders({
                page,
                pageSize: this.pageSize,
                status,
                queryString,
                outliers
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllOrders({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm,
                status: this.status,
                outliers: this.outliers
            });
        }
    }
};
</script>
