import api from './api';

export default {
    async getMenstruationForCurrentUser() {
        const response = await api.get('body-tracker/profile');
        return response;
    },
    async createMenstruationForCurrentUser(payload) {
        const response = await api.post('body-tracker/menstruation', payload);
        return response;
    },
    async updateMenstruationForCurrentUser(payload) {
        const response = await api.post('body-tracker/profile', payload);
        return response;
    },
    async updateMenstruationDataForCurrentUser(payload) {
        const response = await api.post('body-tracker/log', payload);
        return response;
    }
};
