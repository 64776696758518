<template>
    <div
        class="base-toggle-switch-wrapper w-100 d-flex align-items-center justify-content-center justify-content-md-start"
        :class="alignApplyButton === 'right' ? '' : 'flex-column'"
        :style="alignApplyButton === 'right' ? '' : 'gap: 1rem;'"
    >
        <base-toggle-switch
            :is-disabled="isDisabled"
            :label="''"
            :parent-key="mainKey"
            :selected="selected"
            :options="options"
            :track-by="trackBy"
            :label-key="labelKey"
            :allow-deselect="allowDeselect"
            @toggle-changed="updateSelection"
        />
        <div class="width-max-content">
            <loading-btn v-if="showApplyButton" class="btn btn-sm btn-primary ml-2" :listen="listenEvent" @click.prevent="apply"> Apply </loading-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseToggleSwitch from '../../base/base-toggle-switch.vue';

export default {
    components: {
        BaseToggleSwitch
    },
    props: {
        mainKey: {
            type: String,
            default: ''
        },
        alignApplyButton: {
            type: String,
            default: 'right'
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        listenEvent: {
            type: String,
            required: true
        },
        selected: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        allowDeselect: {
            type: Boolean,
            default: false
        },
        trackBy: {
            type: String,
            default: 'id',
            required: true
        },
        labelKey: {
            type: String,
            default: 'label',
            required: true
        }
    },
    data() {
        return {
            defaultSelection: '',
            currentSelection: ''
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        showApplyButton() {
            if (!this.defaultSelection && this.currentSelection) return true;
            if (!this.currentSelection && !this.defaultSelection) return false;

            return this.currentSelection[this.trackBy] !== this.defaultSelection[this.trackBy];
        }
    },
    beforeMount() {
        if (this.selected) {
            this.currentSelection = _.find(this.options, (o) => o[this.trackBy] === this.selected);
            this.defaultSelection = _.find(this.options, (o) => o[this.trackBy] === this.selected);
        }
    },
    methods: {
        apply() {
            this.defaultSelection = this.currentSelection;
            this.$emit('apply', this.currentSelection[this.trackBy]);
        },
        updateSelection(selection) {
            this.currentSelection = selection;
            this.$emit('change', this.currentSelection[this.trackBy]);
        }
    }
};
</script>

<style lang="scss">
@media (max-width: 420px) {
    .base-toggle-switch-wrapper {
        flex-direction: column;
        gap: 1rem;
    }
}
</style>
