import api from './api';

export default {
    create(data) {
        return api.post('quizzes', { data });
    },
    getAll() {
        return api.get('quizzes');
    },
    getStats(quizId) {
        return api.get(`quizzes/${quizId}/stats`);
    },
    getById(id) {
        return api.get(`quizzes/${id}`);
    },
    update(quizId, data) {
        return api.post(`quizzes/${quizId}`, { data });
    },
    remove(quizId) {
        return api.delete(`quizzes/${quizId}`);
    },
    sendAnswers(quizId, answers) {
        return api.post(`quizzes/${quizId}/answers`, { answers });
    },

    updateAssign(quizId, assignData) {
        return api.post(`quizzes/${quizId}/assigns`, { assignData });
    },

    removeAssign(quizId, assignId) {
        return api.delete(`quizzes/${quizId}/assigns/${assignId}`);
    },

    assignQuizzesToUsers(users, quizzes) {
        return api.post('quizzes/assignUsers', { users, quizzes });
    }
};
