<template>
    <div class="">
        <div v-if="loading" class="app-loader d-flex flex-fill flex-column" :class="loaderClasses" :style="`height: ${forceHeight};`">
            <div class="lds-heart">
                <div />
            </div>
        </div>
        <slot v-else />
    </div>
</template>
<script>
export default {
    name: 'Loader',
    props: {
        listen: {
            type: [Array, String],
            required: true
        },
        loaderClass: {
            type: String,
            default: ''
        },
        firstTimeOnly: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        fitContent: {
            type: Boolean,
            default: false
        },
        forceLoading: {
            type: Boolean,
            default: false
        },
        forceHeight: {
            type: String,
            default: '81vh'
        }
    },
    data() {
        return {
            alreadyRan: false,
            time: 0,
            timer: undefined
        };
    },
    computed: {
        loading() {
            let loading = false;
            if (typeof this.listen === 'string') {
                loading = this.$store.state.loading[this.listen];
            } else if (this.listen instanceof Array) {
                loading = this.listen.some((listenTo) => this.$store.state.loading[listenTo]);
            }
            return this.forceLoading || (loading && !(this.alreadyRan && this.firstTimeOnly));
        },
        loaderClasses() {
            let classes = 'align-items-center justify-content-center';

            if (this.left) {
                classes = 'align-items-start justify-content-start';
            }

            if (this.right) {
                classes = 'align-items-end justify-content-end';
            }

            if (this.medium) {
                // to implement the class
                classes += ' loader-medium';
            }

            if (this.small) {
                // to implement the class
                classes += ' loader-small';
            }

            if (this.fitContent) {
                classes += ' fit-content';
            }

            return `${classes} ${this.loaderClass}`;
        }
    }
};
</script>

<style scoped>
@import './loader.scss';
</style>
