class Client {
    parseResponse(response) {
        if (response.request && response.request.responseURL && response.request.responseURL.includes('logout')) {
            window.location.href = '/logout';
        }

        const error = this.getError(response);

        if (error) throw new Error(error);

        return _.get(response, 'data', undefined);
    }

    // eslint-disable-next-line
getError(response) {
        let error = null;

        if (_.get(response, 'data.error')) {
            error = _.get(response, 'data.error', null);
        }

        return error;
    }
}

export default new Client();
