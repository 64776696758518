/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
import { io } from 'socket.io-client';
import { getInstance } from '../utils/auth.instance';

class SocketioService {
    socket;

    constructor() {}

    async setupSocketConnection(userId) {
        const authInstance = getInstance();
        const accessToken = authInstance?.authState?.user?.accessToken;

        if (accessToken) {
            const ioConfig = {
                transports: ['polling', 'websocket', 'webtransport'],
                withCredentials: true,
                query: `token=${accessToken}&userId=${userId}`,
                extraHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            };

            this.socket = io(process.env.VUE_APP_SOCKET_URL, ioConfig);

            console.log('Socket connection instantiated from client');

            this.socket.on('unauthorized', (error) => {
                if (error.data.type === 'UnauthorizedError' || error.data.code === 'invalid_token') {
                    // redirect user to login page perhaps?
                    console.log('User token has expired');
                }
            });
        }

        return this.socket;
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }

    offAny() {
        if (this.socket) {
            this.socket.offAny();
        }
    }
}

export const socketioService = new SocketioService();
