/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import { required, max_value, min_value } from 'vee-validate/dist/rules';
import validator from 'validator';

extend('positive', (value) => {
    return value >= 0;
});
extend('max_value', {
    ...max_value
});

extend('min_value', {
    ...min_value
});

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('', (value) => {
    return validator.isMobilePhone(String(value));
});

extend('phone-number', {
    message: '{_field_} is not a valid phone number',
    validate: (value) => validator.isMobilePhone(String(value))
});

extend('uk-zip-code', {
    message: '{_field_} is not a valid GB one',
    validate: (value) => {
        const regex = new RegExp(
            /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z]{1,2}[0-9][A-Za-z0-9]?)|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z]{1,2}[0-9]{1,2}))))\s?[0-9][A-Za-z]{2})$/i
        );
        return regex.test(String(value));
    }
});

extend('valid-email', {
    message: '{_field_} is not valid',
    validate: (value) => validator.isEmail(String(value))
});
