<template>
    <loader :listen="['result/getAllResultsForAUser']" class="container">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12">
                <ResponsiveTabsDropdown :options="userTabs" :show-count="true" />
                <div class="card">
                    <router-view />
                </div>
                <div class="w-100 d-flex justify-content-center mt-2">
                    <div>
                        <button class="btn btn-primary" @click.prevent="logout()">
                            {{ BUTTONS.logout }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </loader>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { BUTTONS, PROFILE_TABS } from '@/utils/constants';
import ResponsiveTabsDropdown from '../components/global/select/responsive-tabs-dropdown';

export default {
    components: { ResponsiveTabsDropdown },
    data() {
        return {
            BUTTONS,
            PROFILE_TABS
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            hasQuizzes: 'quiz/allFetched',
            allQuizzes: 'quiz/allQuizzes'
        }),
        quizzes() {
            if (!this.user.isPatient) return null;

            let quizzes = this.allQuizzes;

            quizzes = _.filter(
                quizzes,
                (quiz) =>
                    quiz.answers.some((answer) => answer.user === this.user.id) ||
                    Object.values(quiz.assigns).some((assign) => assign.target.type === 'user' && assign.target.value.includes(this.user.id))
            );

            return quizzes;
        },
        userTabs() {
            const tabs = [{ id: PROFILE_TABS.myProfile.id, url: '/profile/', label: PROFILE_TABS.myProfile.label }];

            // if (this.user) {
            //     if (this.user.tenant) {
            //         tabs.push({ id: PROFILE_TABS.tenant.id, url: '/profile/tenant', label: this.user.currentTenant.name });
            //     }

            //     if (this.user.isPatient) {
            //         tabs.push({
            //             id: PROFILE_TABS.quizzes.id,
            //             url: '/profile/quizzes/',
            //             label: PROFILE_TABS.quizzes.label,
            //             count: this.quizzes.filter((quiz) => !quiz.isSpecial()).length || null
            //         });
            //     }
            // }

            return tabs;
        }
    },
    mounted() {
        if (this.user.isPatient && !this.hasQuizzes) {
            this.fetchQuizzes();
        }
    },
    methods: {
        ...mapActions({
            fetchQuizzes: 'quiz/getAll'
        }),
        logout() {
            this.$router.push('/logout');
        }
    }
};
</script>

<style lang="scss">
.col-form-label {
    text-align: left;
    font-weight: 500;
    font-size: 0.875rem !important;
}
</style>
