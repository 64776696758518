<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="logout-text">Logging out...</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ContextHolder } from '@frontegg/vue';
import * as Sentry from '@sentry/browser';
import { socketioService } from '../../services/socketio.service';

export default {
    async created() {
        await this.logout();
    },
    methods: {
        ...mapActions({
            serverLogout: 'auth/logout'
        }),
        async logout() {
            this.$OneSignal.logout();
            await socketioService.disconnect();

            if (!window.location.href.includes('localhost')) {
                Sentry.setUser(null);
            }

            await this.serverLogout();
            localStorage.clear();
            sessionStorage.clear();

            const baseUrl = ContextHolder.getContext().baseUrl;
            window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
        },
        deleteAllCookies() {
            const cookies = document.cookie.split(';');

            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                const eqPos = cookie.indexOf('=');
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            }
        }
    }
};
</script>

<style lang="scss">
.logout-text {
    font-size: 20px;
    font-weight: 400;

    span {
        &:before {
            animation: dots 2s linear infinite;
            content: '';
        }
    }

    @keyframes dots {
        0%,
        20% {
            content: '.';
        }

        40% {
            content: '..';
        }

        60% {
            content: '...';
        }

        90%,
        100% {
            content: '';
        }
    }
}
</style>
