import moment from 'moment';

export default class Answer {
    constructor(data, questions) {
        this.data = data;
        this.questions = questions;
    }

    get user() {
        return this.data.user;
    }

    get date() {
        return moment(this.data.created).format('DD MMMM, YYYY, HH:mm');
    }

    get created() {
        return moment(this.data.created).unix();
    }

    get options() {
        let options;

        try {
            options = JSON.parse(this.data.options);
        } catch (e) {
            options = {};
        }

        return options;
    }

    get responses() {
        return this.questions.map((question) => {
            let response;

            switch (question.type) {
                case 'single':
                    response = question.options.find((option) => option.key === this.options[question.key]);

                    response = response ? response.title : this.options[question.key];

                    break;

                case 'multiple':
                    response = this.options[question.key]
                        .map((optionKey) => {
                            const optionText = question.options.find((option) => option.key === optionKey);

                            return optionText ? optionText.title : optionKey;
                        })
                        .join(', ');
                    break;
                case 'number':
                case 'text':
                case 'date':
                default:
                    response = this.options[question.key];
                    break;
            }

            return {
                key: question.key,
                question: question.title,
                answer: response
            };
        });
    }
}
