<template>
    <b-modal id="modal-scoped" v-model="modalVisible" @hidden="cancel" size="lg">
        <template #modal-header="{ close }">
            <div class="d-flex flex-column align-items-center w-100">
                <h5 class="text-center">{{ title }}</h5>
                <span>{{ description }}</span>
            </div>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
            <hr />
        </template>

        <template #default>
            <div class="d-flex flex-column align-items-center">
                <div v-if="order.additionalInformation?.length" class="col-12">
                    <h6 class="text-left">Notes/Information</h6>
                    <div v-for="(info, index) in order.additionalInformation" :key="index" class="d-flex flex-row justify-content-between my-2">
                        <div class="w-100">
                            <span class="text-muted">(On {{ beautifyDate(info.date) }}):</span> <br />
                            <!-- preserve new lines etc-->
                            <div class="d-flex flex-row w-100 align-items-center">
                                <div class="border p-2 w-100">
                                    <span style="white-space: pre-wrap; word-wrap: break-word">
                                        {{ info.data }}
                                    </span>
                                </div>
                                <div class="col-1">
                                    <button
                                        size="sm"
                                        class="btn empty_background btn-link p-0 m-0"
                                        @click="deleteNote(info.date)"
                                        :disabled="updateAddOrDeleteIsLoading"
                                    >
                                        <i class="fa fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                </div>
                <div class="d-flex flex-column col-12">
                    <label for="additionalInfo" class="form-label">Additional Information</label>
                    <textarea
                        v-model="newInfoToAdd"
                        name="additionalInfo"
                        placeholder="Add notes/information"
                        class="form-control"
                        rows="5"
                        type="text"
                        :disabled="updateAddOrDeleteIsLoading"
                        required
                    />
                </div>
            </div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-info" @click="cancel">Close</button>
            <button class="btn btn-primary" @click="addOrUpdateInfo(newInfoToAdd)" :disabled="!newInfoToAdd || updateAddOrDeleteIsLoading">
                Add Info
            </button>
        </template>
    </b-modal>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        order: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: 'Add notes/info to this order'
        },
        description: {
            type: String,
            default: 'Eg. Particular/Lola conditions or medications'
        }
    },
    data() {
        return {
            modalVisible: false,
            newInfoToAdd: null,
            infoToEdit: null
        };
    },
    computed: {
        updateAddOrDeleteIsLoading() {
            return (
                this.$store.state.loading['order/addOrUpdateAdditionalInfoToOrder'] ||
                this.$store.state.loading['order/deleteAdditionalInfoFromOrder']
            );
        }
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        ...mapActions({
            addOrUpdateAdditionalInfoToOrder: 'order/addOrUpdateAdditionalInfoToOrder',
            deleteAdditionalInfoFromOrder: 'order/deleteAdditionalInfoFromOrder'
        }),
        async addOrUpdateInfo(info) {
            try {
                if (!info) return;
                if (!Array.isArray(info)) {
                    info = [info];
                }

                const payload = {
                    id: this.order.id,
                    additionalInfo: info.map((data) => ({
                        date: new Date(),
                        // preserve new lines,
                        data
                    }))
                };

                await this.addOrUpdateAdditionalInfoToOrder(payload);

                this.newInfoToAdd = null;
            } catch (e) {
                console.error(e);
            }
        },
        async deleteNote(date = null) {
            if (!date) return;
            if (date) date = new Date(date).toISOString();

            this.$confirm({
                message: 'Are you sure you want to delete this note?',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.deleteAdditionalInfoFromOrder({ date, id: this.order.id });
                        } catch (e) {
                            this.$notify({
                                type: 'error',
                                text: _.get(e.response, 'data.message', null) || e.message || 'Error deleting the note'
                            });
                        }
                    }
                }
            });
        },
        cancel() {
            this.$emit('close');
        },
        beautifyDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        }
    }
};
</script>
