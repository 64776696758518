<template>
    <OnboardingScreens />
</template>

<script>
import { mapGetters } from 'vuex';
// import OnboardingScreens from '../components/onboarding-screens';

export default {
    components: {
        // OnboardingScreens
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        })
    }
};
</script>
