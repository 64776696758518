/* eslint-disable no-unused-vars */
import Vue from 'vue';
import _ from 'lodash';
import api from '../api/auth.api';
import UserModel from '../models/user.model';
import { authWait } from './auth.helpers';

export default {
    namespaced: true,
    state: {
        isAuthenticated: false
    },
    getters: {
        isAuthenticated({ isAuthenticated }) {
            return isAuthenticated;
        }
    },
    mutations: {
        setAuthenticated(state, authState) {
            Vue.set(state, 'isAuthenticated', authState);
        }
    },
    actions: {
        async auth({ commit, rootState }) {
            let user = rootState.user.user;
            const loggedIn = !!user.id || !!user.email;

            if (loggedIn) return new UserModel(user);

            commit('activateLoading', 'auth', { root: true });
            try {
                const maxTryCount = 15;
                const waitForSync = await authWait(0, maxTryCount);

                if (!waitForSync) {
                    user = await api.auth();
                    commit('user/setUser', user, { root: true });
                    return new UserModel(user);
                }
            } catch (e) {
                console.log(e);
            } finally {
                commit('deactivateLoading', 'auth', { root: true });
            }
        },
        async logout() {
            try {
                await api.logout();
            } catch (e) {
                console.log(e);
            }
        },
        async setIsAuthenticated({ commit }, isAuthenticated) {
            commit('setAuthenticated', isAuthenticated);
        }
    }
};
