import api from './api';

export default {
    async auth() {
        const data = await api.auth();
        return data;
    },
    async getSignupStatus() {
        const data = await api.getStatus();
        return data;
    },
    async logout() {
        await api.get('logout');
    }
};
