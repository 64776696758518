<template>
    <div>
        <div class="text-editor-wrapper">
            <quill-editor
                ref="TextEditor"
                class="editor border-bottom bg-white"
                :value="initialContent || content"
                :options="editorOption"
                @change="onEditorChange"
            />
        </div>
    </div>
</template>

<script>
import dedent from 'dedent';
import hljs from 'highlight.js';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

export default {
    components: {
        quillEditor
    },
    props: {
        initialContent: {
            default: dedent``,
            type: String
        },
        clearContent: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ font: [] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }]
                        // ['link'],
                    ],
                    syntax: {
                        highlight: (text) => hljs.highlightAuto(text).value
                    }
                }
            },
            content: dedent``
        };
    },
    computed: {
        editor() {
            return this.$refs.TextEditor.quill;
        }
    },
    watch: {
        clearContent: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.content = dedent``;
                }
            }
        }
    },
    methods: {
        // eslint-disable-next-line func-names
        onEditorChange: _.debounce(function (value) {
            this.content = value ? value.html : dedent``;

            this.$emit('contentUpdated', this.content);
        }, 466)
    }
};
</script>

<style lang="scss" scoped>
.text-editor-wrapper {
    z-index: 99999;
    display: flex;
    flex-direction: column;

    .editor {
        height: 15rem; /* Initial height */
        overflow: auto; /* Enable scroll if content overflows */
        resize: vertical; /* Allow vertical resizing */
        min-height: 6rem; /* Set a minimum height to prevent it from collapsing */
        max-height: 50rem; /* Optionally, set a maximum height */
    }
}

.output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
        padding: 1rem;
        height: 16rem;
    }

    &.ql-snow {
        border-top: none;
        height: 10rem;
    }
}
</style>
