import { cloneDeep } from 'lodash';

export default class Assign {
    static get defaultData() {
        return {
            answers: {
                type: '',
                interval: {
                    amount: 1,
                    type: '',
                    day: '1',
                    time: '18:00'
                }
            },
            target: {
                type: '',
                value: ''
            },
            notification: ''
        };
    }

    constructor(data) {
        this.data = {
            ...Assign.defaultData,
            ...data
        };
    }

    get id() {
        return this.data.id;
    }

    get target() {
        return this.data.target;
    }

    get answerType() {
        return this.data.answers.type;
    }

    includes(userId) {
        /* TODO- check this */
        return Array.isArray(this.target?.value) && this.target?.value?.includes(userId);
    }

    export() {
        return cloneDeep(this.data);
    }
}
