/* eslint no-underscore-dangle: "off" */

export default class Model {
    _state = {};

    _getters = {};

    _attributes = {};

    constructor(attributes = {}, state = {}, getters = {}, defaults) {
        // Set default values that will be available even if we create empty model
        if (defaults) _.defaults(attributes, defaults);

        this._attributes = { ...attributes };
        _.each(attributes, (value, key) => {
            const modelDescriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), key);

            // We cannot set a value when there is a getter without a setter
            if (_.get(modelDescriptor, 'get') && !_.get(modelDescriptor, 'set')) return;

            this[key] = value;
        });

        this.state = state;
        this.getters = getters;
    }

    set attributes(attributes) {
        this._attributes = attributes;
    }

    get attributes() {
        return this._attributes;
    }

    get id() {
        return this.attributes.id || this.attributes._id;
    }

    set id(id) {
        this.attributes.id = id;
    }

    set state(state) {
        this._state = state;
    }

    get state() {
        return this._state || {};
    }

    set getters(getters) {
        this._getters = getters;
    }

    get getters() {
        return this._getters || {};
    }

    toJSON() {
        return _.mapValues(this._attributes, (value, key) => this[key]);
    }
}
