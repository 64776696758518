/* eslint-disable no-unused-vars */
import Vue from 'vue';
import api from '../api/order.api';
import OrderModel from '../models/order.model';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        userOrders: {},
        orders: {
            page: 1,
            page_size: 20,
            total: 0,
            queryString: null,
            status: null,
            results: null,
            outliers: null,
            data: {}
        }
    },
    getters: {
        allUserOrders({ userOrders }, getters, rootState, rootGetters) {
            return _.mapValues(userOrders, (orders) => orders.map((o) => new OrderModel(o, rootState, rootGetters)));
        },
        allOrders({ orders }, getters, rootState, rootGetters) {
            return _.mapValues(orders.data, (order) => new OrderModel(order, rootState, rootGetters));
        },
        page(state) {
            return state.orders.page;
        },
        pageSize(state) {
            return state.orders.page_size;
        },
        total(state) {
            return state.orders.total;
        },
        queryString(state) {
            return state.orders.queryString;
        },
        status(state) {
            return state.orders.status;
        },
        results(state) {
            return state.orders.results;
        },
        outliers(state) {
            return state.orders.outliers;
        }
    },
    mutations: {
        updateOrder(state, order) {
            if (state.orders.data?.[order.id]) {
                Vue.set(state.orders.data, order.id, { ...state.orders.data[order.id], ...order });
            }

            const userOrders = state.userOrders[order.userId];

            if (userOrders?.[order.userId]?.length) {
                const orderIndex = _.findIndex(userOrders[order.userId], { id: order.id });

                if (orderIndex >= 0) {
                    Vue.set(userOrders[order.userId], orderIndex, { ...userOrders[order.userId][orderIndex], ...order });
                } else {
                    userOrders[order.userId].push(order);
                }
            }
        },
        updateOrderStatus(state, { id, status, userId }) {
            const userOrders = state.userOrders[userId];

            if (userOrders?.length) {
                const orderIndex = _.findIndex(userOrders, { id });
                if (orderIndex >= 0) {
                    Vue.set(userOrders[orderIndex], 'status', status);
                }
            }

            if (state.orders?.data?.[id]) {
                Vue.set(state.orders.data[id], 'status', status);
            }
        },
        setUserOrders(state, { userId, userOrders }) {
            Vue.set(state.userOrders, userId, userOrders || []);
        },
        setOrders(state, orders) {
            Vue.set(state.orders, 'data', {});

            _.each(orders.data, (order) => {
                Vue.set(state.orders.data, order.id, order);
            });

            Vue.set(state.orders, 'page', orders.page || 1);
            Vue.set(state.orders, 'page_size', orders.outliers ? 20 : orders.page_size || 20);
            Vue.set(state.orders, 'total', orders.total || 0);
            Vue.set(state.orders, 'status', orders.status || null);
            Vue.set(state.orders, 'results', orders.results || null);
            Vue.set(state.orders, 'outliers', orders.outliers || null);
            Vue.set(state.orders, 'queryString', orders.query_string || null);
        },
        setPagination(state, { page, pageSize, status, queryString, results }) {
            Vue.set(state.orders, 'page', page || 1);
            Vue.set(state.orders, 'page_size', pageSize || 20);
            Vue.set(state.orders, 'queryString', queryString || null);
            Vue.set(state.orders, 'results', results || null);
            Vue.set(state.orders, 'outliers', results || null);
            Vue.set(state.orders, 'status', status || null);
        }
    },
    actions: {
        async updateOrder({ commit }, order) {
            commit('activateLoading', 'order/updateOrder', { root: true });
            try {
                if (order.user) Vue.delete(order, 'user');
                if (order.result) Vue.delete(order, 'result');

                await api.updateOrder(order.id, order);

                commit('updateOrder', order);
            } finally {
                commit('deactivateLoading', 'order/updateOrder', { root: true });
            }
        },
        async updateOrderStatus({ commit, dispatch }, payload) {
            commit('activateLoading', 'order/updateOrderStatus', { root: true });
            commit('activateLoading', `order/updateOrderStatus/${payload.id}`, { root: true });
            try {
                await api.updateOrderStatus(payload.id, payload.status);
                commit('updateOrderStatus', payload);
                dispatch('getAllOrders', { withLoading: false });
            } finally {
                commit('deactivateLoading', 'order/updateOrderStatus', { root: true });
                commit('deactivateLoading', `order/updateOrderStatus/${payload.id}`, { root: true });
            }
        },
        async getAllUserOrders({ commit }, userId) {
            commit('activateLoading', 'order/getAllUserOrders', { root: true });
            try {
                const userOrders = await api.getOrdersForUser(userId);
                if (userOrders && userOrders.length) commit('setUserOrders', { userId, userOrders });
            } catch (e) {
                commit('setUserOrders', { userId, userOrders: [] });
            } finally {
                commit('deactivateLoading', 'order/getAllUserOrders', { root: true });
            }
        },
        async getAllOrders(
            { commit, state },
            {
                withLoading = true,
                page = state.orders.page,
                pageSize = state.orders.page_size,
                queryString = state.orders.queryString,
                status = state.orders.status,
                results = state.orders.results,
                outliers = state.orders.outliers
            } = {}
        ) {
            if (withLoading) commit('activateLoading', 'order/getAllOrders', { root: true });
            try {
                if (
                    page === state.orders.page &&
                    pageSize === state.orders.page_size &&
                    queryString &&
                    queryString === state.orders.queryString &&
                    results === state.orders.results &&
                    outliers === state.orders.outliers &&
                    status === state.orders.status
                ) {
                    commit('deactivateLoading', 'result/getAllOrders', { root: true });
                    return;
                }

                commit('setPagination', { page, pageSize, queryString, status, results, outliers });

                const response = await api.getAllOrders(true, {
                    status,
                    page,
                    page_size: pageSize,
                    query_string: queryString,
                    outliers,
                    results
                });

                commit('setOrders', response);
            } finally {
                if (withLoading) commit('deactivateLoading', 'order/getAllOrders', { root: true });
            }
        },
        async getOrder({ commit }, orderId) {
            commit('activateLoading', 'order/getOrder', { root: true });
            try {
                const order = await api.getOrder(orderId);
                commit('setOrder', order);
            } catch (e) {
                commit('setOrder', null);
            } finally {
                commit('deactivateLoading', 'order/getOrder', { root: true });
            }
        },
        async addOrUpdateAdditionalInfoToOrder({ commit }, { id, additionalInfo }) {
            commit('activateLoading', `order/addOrUpdateAdditionalInfoToOrder/${id}`, { root: true });
            try {
                const order = await api.addOrUpdateAdditionalInfoToOrder(id, additionalInfo);
                commit('updateOrder', order);
            } finally {
                commit('deactivateLoading', `order/addOrUpdateAdditionalInfoToOrder/${id}`, { root: true });
            }
        },
        async deleteAdditionalInfoFromOrder({ commit }, { id, date }) {
            commit('activateLoading', `order/deleteAdditionalInfoFromOrder/${id}`, { root: true });
            try {
                const order = await api.deleteAdditionalInfoFromOrder(id, date);
                commit('updateOrder', order);
            } finally {
                commit('deactivateLoading', `order/deleteAdditionalInfoFromOrder/${id}`, { root: true });
            }
        },
        async generateQRCode({ commit }, id) {
            commit('activateLoading', `order/generateQRCode/${id}`, { root: true });
            try {
                const qrCode = await api.generateQRCodeForOrder(id);

                return qrCode;
            } finally {
                commit('deactivateLoading', `order/generateQRCode/${id}`, { root: true });
            }
        },
        async generateKitDispatchCSV({ commit }, { orderIds, option, addQR } = {}) {
            commit('activateLoading', 'order/generateKitDispatchCSV', { root: true });
            try {
                const response = await api.generateKitDispatchCSV(orderIds, option, addQR);

                return response;
            } finally {
                commit('deactivateLoading', 'order/generateKitDispatchCSV', { root: true });
            }
        }
        // async deleteOrder({ commit, dispatch }, id) {
        //     commit('activateLoading', `user/deleteOrder/${id}`, { root: true });
        //     try {
        //         await api.deleteOrder(id);
        //         dispatch('getAllUserOrders');
        //     } finally {
        //         commit('deactivateLoading', `user/deleteOrder/${id}`, { root: true });
        //     }
        // }
    }
};
