import Question from '@/store/models/quiz/question';
import Assign from '@/store/models/quiz/assign';
import Answer from '@/store/models/quiz/answer';

import Store from '@/store';

export default class Quiz {
    static get lolaGoalsQuiz() {
        return 'ypl-goals';
    }

    static get lolaDietQuiz() {
        return 'ypl-buddy-diet';
    }

    static get lolaTrainingQuiz() {
        return 'ypl-buddy-training';
    }

    constructor(data = {}) {
        this.data = data;

        this.prepareData();
    }

    prepareData() {
        this.questions = (this.data.questions || []).map((question) => new Question(question));

        this.assigns = {};
        if (Array.isArray(this.data?.assigns)) {
            this.data.assigns.forEach((assignData) => {
                this.assigns[assignData.id] = new Assign(assignData);
            });
        }

        this.answers = (this.data.answers || []).map((answer) => new Answer(answer, this.questions));
    }

    update(data) {
        this.data = {
            ...this.data,
            ...data
        };

        this.prepareData();
    }

    hasAssigns() {
        return Object.values(this.assigns).length > 0;
    }

    updateAssign(assignData) {
        this.assigns[assignData.id] = new Assign(assignData);
    }

    removeAssign(assignId) {
        delete this.assigns[assignId];
    }

    isForPatient(patientId) {
        return Object.values(this.assigns).some((assign) => assign.user === patientId);
    }

    hasAnswers(userId = null) {
        if (userId === null) {
            userId = Store.getters['user/currentUser'].id;
        }

        return this.answers.some((answer) => answer.user === userId);
    }

    canAnswer(userId = null) {
        if (userId === null) {
            userId = Store.getters['user/currentUser'].id;
        }

        /* TODO check number of submissions and if he is assigned */
        return !!this; // Object.values(this.assigns).some((assign) => assign.target.values.includes(userId));
    }

    hasSingleAnswer() {
        return !!this.data.singleAnswer;
    }

    isSpecial() {
        return !!this.data.isSpecial;
    }

    get id() {
        return this.data.id;
    }

    get key() {
        return this.data.key;
    }

    get title() {
        return this.data.title;
    }

    get description() {
        return this.data.description;
    }

    get created() {
        return this.data.created;
    }

    get lastModified() {
        return this.data.lastModified;
    }
}
