/* eslint-disable import/no-cycle */
import api from '../api/tenant.api';

export default {
    namespaced: true,
    actions: {
        async updateTenantInfoFromMetadata({ commit }, payload) {
            commit('activateLoading', 'tenant/updateTenantInfoFromMetadata', { root: true });
            try {
                const response = await api.updateTenantInfoFromMetadata(payload);
                commit('updateUserProfile', { tenant: response });

                return response;
            } finally {
                commit('deactivateLoading', 'tenant/updateTenantInfoFromMetadata', { root: true });
            }
        },
        async updateTenantPregnancyOutcomeSystemStatus({ commit }, status) {
            commit('activateLoading', 'tenant/updateTenantPregnancyOutcomeSystemStatus', { root: true });
            try {
                const response = await api.updateTenantPregnancyOutcomeSystemStatus({ status });
                commit('updateUserProfile', { tenant: response });

                return response;
            } finally {
                commit('deactivateLoading', 'tenant/updateTenantPregnancyOutcomeSystemStatus', { root: true });
            }
        }
    }
};
