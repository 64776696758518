import Vue from 'vue';
import quizApi from '@/store/api/quiz.api';
import Quiz from '@/store/models/quiz/quiz';

export default {
    namespaced: true,
    state: {
        quizzes: null,
        allFetched: false,
        stats: {}
    },
    getters: {
        allFetched: (state) => state.allFetched,
        allQuizzes: (state) => state.quizzes,
        byId: (state) => (quizId) => state.quizzes?.find((quiz) => quiz.id === quizId),
        special: (state) => (key) => state.quizzes?.find((quiz) => quiz.key === key),

        stats: (state) => (quizId) => state.stats ? state.stats[quizId] : {}
    },
    actions: {
        create({ commit }, data) {
            return new Promise((resolve) => {
                quizApi.create(data).then((newQuiz) => {
                    commit('setQuizzes', [newQuiz]);

                    resolve({
                        message: 'Quiz created.'
                    });
                });
            }).catch((e) => {
                console.log(e);
            });
        },
        getById({ commit }, id) {
            return quizApi
                .getById(id)
                .then((quiz) => {
                    if (quiz?.id) {
                        if (id !== quiz?.id) {
                            /* special quiz */
                            quiz.key = id;
                        }

                        commit('setQuizzes', [quiz]);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getAll({ commit }) {
            commit('activateLoading', 'quiz/fetchQuizzes', { root: true });
            quizApi
                .getAll()
                .then((quizzes) => {
                    commit('setQuizzes', quizzes);
                    commit('setAllFetched', true);
                })
                .catch((e) => {
                    console.log(e);
                    commit('deactivateLoading', 'quiz/fetchQuizzes', { root: true });
                })
                .finally(() => {
                    commit('deactivateLoading', 'quiz/fetchQuizzes', { root: true });
                });
        },
        getStats({ commit }, quizId) {
            return quizApi
                .getStats(quizId)
                .then((stats) => {
                    commit('setStats', { quizId, stats });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        update({ commit }, { id, ...quizData }) {
            return new Promise((resolve) => {
                quizApi
                    .update(id, quizData)
                    .then((response) => {
                        commit('updateQuiz', {
                            id,
                            ...quizData
                        });

                        resolve(response);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            });
        },
        remove({ commit }, quizId) {
            return new Promise((resolve) => {
                quizApi
                    .remove(quizId)
                    .then((response) => {
                        commit('removeQuiz', quizId);

                        resolve(response);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            });
        },
        sendAnswers({ commit }, { quizId, answers }) {
            return quizApi
                .sendAnswers(quizId, answers)
                .then(() => {
                    commit('updateQuiz', {
                        id: quizId,
                        answers: [answers]
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        updateAssign({ commit }, { quizId, assignData }) {
            quizApi
                .updateAssign(quizId, assignData)
                .then((assign) => {
                    commit('updateAssign', { quizId, assign });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        removeAssign({ commit }, { quizId, assignId }) {
            quizApi
                .removeAssign(quizId, assignId)
                .then(() => {
                    commit('removeAssign', { quizId, assignId });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        assignToUsers({ commit }, { userId, quizzes }) {
            quizApi
                .assignQuizzesToUsers([userId], quizzes)
                .then(() => {
                    /* TODO --> assign */
                    quizApi.getAll().then((allQuizzes) => {
                        commit('setQuizzes', allQuizzes);
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    },
    mutations: {
        setQuizzes(state, quizzes) {
            if (state.quizzes === null) {
                state.quizzes = [];
            }

            quizzes.forEach((quizData) => {
                if (!state.quizzes.find((quiz) => quiz.id === quizData.id)) {
                    state.quizzes.push(new Quiz(quizData));
                }
            });
        },
        updateQuiz(state, quizData) {
            state.quizzes.find((quiz) => quiz.id === quizData.id).update(quizData);
        },
        removeQuiz(state, quizId) {
            state.quizzes = state.quizzes.filter((quiz) => quiz.id !== quizId);
        },
        updateAssign(state, { quizId, assign }) {
            state.quizzes.find((quiz) => quiz.id === quizId).updateAssign(assign);

            state.quizzes = [...state.quizzes];
        },
        removeAssign(state, { quizId, assignId }) {
            state.quizzes.find((quiz) => quiz.id === quizId).removeAssign(assignId);

            state.quizzes = [...state.quizzes];
        },
        setAllFetched(state, fetched) {
            state.allFetched = fetched;
        },
        setStats(state, { quizId, stats }) {
            Vue.set(state.stats, quizId, stats);
        }
    }
};
