<template>
    <div class="output ql-snow border-0">
        <div class="ql-editor" v-html="$sanitize(content)" />
    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
    props: {
        content: {
            default: '<p>No comments were added by the doctor</p>',
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.output {
    margin: 0;
    border: 1px solid #ccc !important;
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: auto; /* Enables horizontal scrolling */
    resize: vertical;
    max-height: 15rem;
    &.code {
        padding: 1rem;
    }
    &.ql-snow {
        border-top: none;
    }
}
</style>
