<template>
    <div>
        <div class="d-flex flex-column align-items-center">
            <div class="d-flex flex-row" style="width: 150px; padding: 10px">
                <img
                    src="../assets/svg/icons/random/office.svg"
                    alt="Profile Picture"
                    class="img-fluid img-thumbnail mt-4"
                    style="width: 150px; z-index: 1"
                />
            </div>
            <div class="ml-3 d-flex align-items-end">
                <h5>{{ user.currentTenant.name }}</h5>
            </div>

            <!-- <select
                id="select" v-model="currentTenant" name="select"
                class="custom-select" style="min-width: 8rem;" required>
                <option v-for="tenant in tenantsInfo" :key="tenant.id" :value="tenant.id">
                    <div class="ml-3 d-flex align-items-end">
                        <h5>{{ tenant.name }}</h5>
                    </div>
                </option>
            </select> -->
            <b-form-select v-model="currentTenant" :options="tenantsInfo" class="col-6">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                    <b-form-select-option :value="''" disabled> Switch Tenant </b-form-select-option>
                </template>
            </b-form-select>
        </div>
        <div class="card-body p-4 text-black">
            <div class="mb-3 text-left">
                <p class="lead fw-normal mb-1 d-flex justify-content-md-between flex-column flex-md-row align-items-center align-items-md-start">
                    About Tenant
                    <button
                        v-can-access.role="'admin'"
                        icon="fa fa-arrow"
                        class="btn btn-link w-auto"
                        @click.prevent="
                            enableTenantInfoEdit = !enableTenantInfoEdit;
                            resetTenantUpdatedInfo();
                        "
                    >
                        {{ enableTenantInfoEdit ? 'Cancel' : 'Edit' }}
                        <i class="fa fa-edit fa-xs pl-1" />
                    </button>
                </p>
                <div class="p-4" style="background-color: #f8f9fa">
                    <div v-if="!enableTenantInfoEdit">
                        <div v-if="tenantInfo?.length" class="d-flex flex-column">
                            <div v-for="(info, index) in tenantInfo" :key="index">
                                <span class="font-weight-bold mr-1"> {{ info.label }}</span
                                >: {{ info.value }}
                            </div>
                        </div>
                        <div v-else>No info has been added about this tenant</div>
                    </div>
                    <div v-else class="d-flex flex-column">
                        <div v-if="tenantUpdatedInfo?.length">
                            <div v-if="tenantInfo?.length" v-can-access.role="'admin'" class="d-flex flex-column pb-1">
                                <label class="mb-0 pb-0 font-weight-bold">Existing info:</label>
                                <span class="small text-muted">Can be seen by everybody</span>
                            </div>
                            <div v-for="(_info, index) in tenantUpdatedInfo" :key="index">
                                <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-end">
                                    <div class="form-group col-12 col-lg-3">
                                        <label>Label</label>
                                        <input
                                            v-model="tenantUpdatedInfo[index].label"
                                            type="text"
                                            name="Label"
                                            class="form-control"
                                            placeholder="Label - eg. Phone"
                                            required
                                        />
                                    </div>
                                    <div class="form-group col-12 col-lg-6 my-lg-0 my-2">
                                        <label>Label Value</label>

                                        <input
                                            v-model="tenantUpdatedInfo[index].value"
                                            type="text"
                                            name="Label Value"
                                            class="form-control"
                                            placeholder="Label Value - eg. 1234567"
                                            required
                                        />
                                    </div>
                                    <div class="form-group col-12 col-lg-3">
                                        <label>Actions</label>
                                        <div>
                                            <button icon="fa fa-arrow" class="btn btn-link w-auto" @click.prevent="removeTenantInfo(index)">
                                                <i class="fa fa-trash" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label class="font-weight-bold pt-2 pb-1">Add new info</label>
                        <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-end">
                            <div class="form-group col-12 col-lg-3">
                                <label>Label</label>
                                <input
                                    v-model="tenantNewSingleInfo.label"
                                    type="text"
                                    name="Label"
                                    class="form-control"
                                    placeholder="Label - eg. Phone"
                                    required
                                />
                            </div>
                            <div class="form-group col-12 col-lg-6 my-lg-0 my-2">
                                <label>Label Value</label>

                                <input
                                    v-model="tenantNewSingleInfo.value"
                                    type="text"
                                    name="Label Value"
                                    class="form-control"
                                    placeholder="Label Value - eg. 1234567"
                                    required
                                />
                            </div>
                            <div class="form-group col-12 col-lg-3">
                                <label>Actions</label>
                                <div>
                                    <button
                                        icon="fa fa-arrow"
                                        class="btn btn-link w-auto"
                                        :disabled="!tenantNewSingleInfo.value || !tenantNewSingleInfo.label"
                                        @click="addTenantCustomValue"
                                    >
                                        <i class="fa fa-plus" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <loading-btn
                                :listen="['tenant/updateTenantInfoFromMetadata']"
                                class="btn btn-primary w-auto"
                                :disabled="saveTenantInfoDisabled"
                                @click="updateTenantInfo"
                            >
                                Save Changes
                            </loading-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!user.isPatient" class="mb-3 text-left">
                <p class="lead fw-normal mb-1 d-flex justify-content-md-between flex-column flex-md-row align-items-center align-items-md-start">
                    <span class="d-flex flex-column align-items-center align-items-md-start">
                        <span class="d-flex">
                            Urgent Contact
                            <span
                                v-b-tooltip.hover.left
                                class="ml-1 font-weight-bold"
                                :title="
                                    user.isPatient
                                        ? 'If you do not feel fine and especially if you have the symptoms mentioned below, please contact your tenant at the mentioned info'
                                        : 'If you want your patients to contact you in a specific case and way, enable this option and add info.'
                                "
                            >
                                <font-awesome-icon icon="fa fa-info-circle" />
                            </span>
                        </span>
                        <small v-can-access.role="'admin doctor'" class="p-0 m-0">
                            <small class="p-0 m-0">
                                <small class="text-muted p-0 m-0">
                                    {{ tenantUrgentContactInfo.enabled ? 'Enabled' : 'Disabled' }}
                                </small>
                            </small>
                        </small>
                    </span>
                    <button
                        v-can-access.role="'admin'"
                        icon="fa fa-arrow"
                        class="btn btn-link w-auto"
                        @click.prevent="
                            enableTenantUrgentInfoEdit = !enableTenantUrgentInfoEdit;
                            resetTenantUrgentInfo();
                        "
                    >
                        {{ enableTenantUrgentInfoEdit ? 'Cancel' : 'Edit' }}
                        <i class="fa fa-edit fa-xs pl-1" />
                    </button>
                </p>
                <div class="p-4" style="background-color: #f8f9fa">
                    <div v-if="!enableTenantUrgentInfoEdit" class="d-flex flex-column">
                        <div v-if="tenantUrgentContactInfo.details && tenantUrgentContactInfo.when">
                            <div class="d-flex flex-column mb-2">
                                <span class="font-weight-bold mr-1"> Ways to {{ user.isPatient ? 'contact the tenant' : 'be contacted' }} </span>
                                <span style="white-space: pre">{{ tenantUrgentContactInfo.details }}</span>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold mr-1">
                                    When should
                                    {{ user.isPatient ? 'you contact the tenant' : 'the patient contact you' }}
                                </span>
                                <span style="white-space: pre">{{ tenantUrgentContactInfo.when }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else v-can-access.role="'admin'" class="d-flex flex-column pb-1">
                        <div class="mb-2">
                            <b-form-checkbox v-model="tenantUrgentContactInfo.enabled" v-can-access.role="'admin'" class="small" switch size="sm">
                                {{ tenantUrgentContactInfo.enabled ? 'Enabled' : 'Disabled' }}
                            </b-form-checkbox>
                            <span class="small text-muted">If enabled, can be seen by all patients</span>
                        </div>

                        <div class="d-flex flex-column mb-2">
                            <label class="mb-0 pb-0 font-weight-bold">How should you {{ user.isPatient ? 'contact' : 'be contacted' }}:</label>
                            <b-form-textarea
                                id="textarea-details"
                                v-model="tenantUrgentContactInfo.details"
                                size="sm"
                                placeholder="Eg. By phone at..., by email at..., etc."
                            />
                        </div>

                        <div class="d-flex flex-column">
                            <label class="mb-0 pb-0 font-weight-bold"
                                >When should {{ user.isPatient ? 'you contact the tenant' : 'patient contact you' }}:</label
                            >
                            <b-form-textarea
                                id="textarea-when"
                                v-model="tenantUrgentContactInfo.when"
                                size="sm"
                                placeholder="Eg. If you feel sick, if you have the symptoms xyz, etc."
                            />
                        </div>

                        <div class="mt-2">
                            <loading-btn
                                :listen="['tenant/updateTenantInfoFromMetadata']"
                                class="btn btn-primary w-auto"
                                :disabled="saveTenantUrgentInfoDisabled"
                                @click="updateUrgentTenantInfo"
                            >
                                Save Changes
                            </loading-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div v-can-access.role="'admin doctor'" class="mb-3 text-left">
                <p class="lead fw-normal mb-1 d-flex flex-column flex-md-row align-items-center align-items-md-start">
                    <span class="d-flex flex-column align-items-center align-items-md-start">
                        <div class="d-flex flex-column flex-md-row align-items-center">
                            <span class="d-flex">
                                Pregnancy Outcome System
                                <span
                                    v-b-tooltip.hover.left
                                    class="ml-1 font-weight-bold"
                                    :title="`Designed for pregnant women to update the organization about their pregnancy status. ${
                                        user.isPatient
                                            ? ''
                                            : 'If enabled, female users will have on their profile an option to update their pregnancy status.'
                                    }`"
                                >
                                    <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                            </span>
                            <b-form-checkbox v-model="enablePregnancyOutcomeSystem" switch class="d-flex mx-2" />
                        </div>
                        <small v-can-access.role="'admin doctor'" class="p-0 m-0">
                            <small class="p-0 m-0">
                                <small class="text-muted p-0 m-0">
                                    {{ enablePregnancyOutcomeSystem ? 'Enabled' : 'Disabled' }}
                                </small>
                            </small>
                        </small>
                    </span>
                </p>
            </div>
            <div v-can-access.role="'admin'" class="mb-3 text-left">
                <p class="lead fw-normal mb-1 d-flex justify-content-md-between flex-column flex-md-row align-items-center align-items-md-start">
                    Other
                </p>
                <div class="p-4" style="background-color: #f8f9fa">
                    <div v-can-access.role="'admin'" class="my-3">
                        <span> Security & Admin Specific Settings: </span>
                        <div class="mt-2">
                            <button class="btn btn-primary" @click="showAdminPortal">Manage</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { AdminPortal } from '@frontegg/vue';
import LoadingBtn from '@/components/global/buttons/loading-btn';

export default {
    components: { LoadingBtn },
    data() {
        return {
            enableTenantInfoEdit: false,
            enableTenantUrgentInfoEdit: false,
            tenantNewSingleInfo: {
                label: '',
                value: ''
            },
            tenantUpdatedInfo: {},
            tenantUrgentContactInfo: {
                enabled: false,
                details: '',
                when: ''
            },
            enablePregnancyOutcomeSystem: false,
            tenantsInfo: [],
            ...this.mapAuthState(),
            ...this.mapTenantsState(),
            currentTenant: {
                text: 'Switch tenant',
                value: null
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        tenantInfo() {
            return this.user.currentTenant?.metadata?.info?.aboutList
                ? _.isEmpty(this.user.currentTenant?.metadata?.info?.aboutList)
                    ? []
                    : this.user.currentTenant?.metadata?.info?.aboutList
                : [];
        },
        tenantUrgentInfo() {
            return this.user.currentTenant?.metadata?.info?.urgentContact
                ? _.isEmpty(this.user.currentTenant?.metadata?.info?.urgentContact)
                    ? {}
                    : this.user.currentTenant?.metadata?.info?.urgentContact
                : {};
        },
        saveTenantInfoDisabled() {
            return _.isEqual(this.tenantInfo, this.tenantUpdatedInfo);
        },
        saveTenantUrgentInfoDisabled() {
            return (
                !this.tenantUrgentContactInfo.when ||
                !this.tenantUrgentContactInfo.details ||
                _.isEqual(this.tenantUrgentInfo, this.tenantUrgentContactInfo)
            );
        }
    },
    watch: {
        enablePregnancyOutcomeSystem: {
            handler(val) {
                if (!this.user.isPatient && val !== this.user.currentTenant?.metadata?.info?.pregnancyOutcomeSystem)
                    this.updateTenantPregnancyOutcomeSystemStatus(!!val);
            }
        },
        currentTenant: {
            handler(val) {
                if (this.user && _.isString(val) && val !== this.user.currentTenant?.id) {
                    this.switchTenant(String(val));
                }
            }
        }
    },
    async created() {
        this.tenantsInfo = this.authState.tenantsState?.tenants.map((t) => ({
            text: t.name,
            value: t.tenantId
        }));

        this.currentTenant = {
            text: this.user.currentTenant?.name,
            value: this.user.currentTenant?.id
        };

        this.resetTenantUpdatedInfo();
        this.resetTenantUrgentInfo();

        this.enablePregnancyOutcomeSystem = this.user.currentTenant?.metadata?.info?.pregnancyOutcomeSystem;
    },
    methods: {
        ...mapActions({
            updateTenantInfoFromMetadata: 'tenant/updateTenantInfoFromMetadata',
            updateTenantPregnancyOutcomeSystemStatus: 'tenant/updateTenantPregnancyOutcomeSystemStatus'
        }),
        showAdminPortal() {
            AdminPortal.show();
        },
        addTenantCustomValue() {
            this.tenantUpdatedInfo.push(this.tenantNewSingleInfo);
            this.tenantNewSingleInfo = {};
        },
        removeTenantInfo(index) {
            this.tenantUpdatedInfo = _.remove(this.tenantUpdatedInfo, (_v, i) => i !== index);
        },
        async updateTenantInfo() {
            this.tenantUpdatedInfo = this.tenantUpdatedInfo.map((info) => ({
                ...info,
                label: this.$sanitize(info.label),
                value: this.$sanitize(info.value)
            }));

            try {
                await this.updateTenantInfoFromMetadata({ aboutList: this.tenantUpdatedInfo });
                this.$notify({
                    type: 'success',
                    text: 'Success! Tenant info has been updated'
                });

                this.$router.push('/profile');
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: _.get(e.response, 'data.message', null) || e.message || "Tenant info couldn't be updated. Please try again later."
                });
            }
        },
        async updateUrgentTenantInfo() {
            try {
                this.tenantUrgentContactInfo = {
                    ...this.tenantUrgentContactInfo,
                    details: this.$sanitize(this.tenantUrgentContactInfo.details),
                    when: this.$sanitize(this.tenantUrgentContactInfo.when)
                };

                await this.updateTenantInfoFromMetadata({
                    urgentContact: this.tenantUrgentContactInfo
                });
                this.$notify({
                    type: 'success',
                    text: 'Success! Tenant info has been updated'
                });

                this.$router.push('/profile');
            } catch (e) {
                this.$notify({
                    type: 'error',
                    text: _.get(e.response, 'data.message', null) || e.message || "Tenant info couldn't be updated. Please try again later."
                });
            }
        },
        resetTenantUpdatedInfo() {
            this.tenantUpdatedInfo = _.cloneDeep(this.user.currentTenant?.metadata?.info?.aboutList) || [];
        },
        resetTenantUrgentInfo() {
            this.tenantUrgentContactInfo = _.cloneDeep(this.user.currentTenant?.metadata?.info?.urgentContact) || this.tenantUrgentContactInfo;
        },
        async switchTenant(tenantId) {
            // Use this.authState.user.tenantIds to get the tenants the user is a member of
            await this.fronteggAuth.tenantsActions.switchTenant({ tenantId });
            setTimeout(() => window.location.reload(), 1000);
        }
    }
};
</script>

<style lang="scss">
.col-form-label {
    text-align: left;
    font-weight: 500;
    font-size: 0.875rem !important;
}
</style>
