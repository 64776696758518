import store from '../store';
import { OrganisationPermissions, Roles, SubscriptionEntitlementName } from '../utils/constants';

async function checkIfCanAccess(el, { value, modifiers }, vnode) {
    let keep = false;
    const user = store.getters['user/currentUser'];

    if (modifiers.admin) {
        keep = user.canAccess(Roles.ADMIN);
    }

    if (modifiers.permission && user.hasAnyPermission(Roles[value?.toUpperCase()] || OrganisationPermissions[value?.toUpperCase()] || value)) {
        keep = true;
    }

    if (modifiers.role && user.canAccess(OrganisationPermissions[value?.toUpperCase()] || value)) {
        keep = true;
    }

    if (modifiers.entitlement && user.hasAnyEntitlement(SubscriptionEntitlementName[value] || value)) {
        keep = true;
    }

    if (!keep) {
        if (vnode.elm.parentElement) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        } else {
            vnode.elm.remove();
        }
    }
}

export default {
    bind: checkIfCanAccess,
    inserted: checkIfCanAccess,
    componentUpdated: checkIfCanAccess
};
