/* eslint-disable import/no-cycle */
import Vue from 'vue';
import api from '../api/user.api';
import WearModel from '../models/wear.model';

export default {
    namespaced: true,
    state: {
        currentWear: {},
        wearData: {},
        wearDataPerUser: {}
    },
    getters: {
        availableWearData(state, getters, rootState, rootGetters) {
            return _(state.wearData)
                .map((wear) => new WearModel(wear, rootState, rootGetters))
                .keyBy('deviceId')
                .value();
        },
        availableWearDataPerUser(state, getters, rootState, rootGetters) {
            const wearWithUserId = {};

            _.each(state.wearDataPerUser, (singleWearData, userId) => {
                const wearInfo = _(singleWearData)
                    .map((wear) => new WearModel(wear, rootState, rootGetters))
                    .keyBy('deviceId')
                    .value();

                wearWithUserId[userId] = wearInfo;
            });

            return wearWithUserId;
        },
        currentWear(state, getters, rootState, rootGetters) {
            return new WearModel(state.currentWear, rootState, rootGetters);
        }
    },
    mutations: {
        setCurrentWearData(state, wearData) {
            state.currentWear = wearData;
        },
        setWearData(state, wearData) {
            if (!state.wearData) {
                Vue.set(state, 'wearData', {});
            }

            Vue.set(state.wearData, wearData.deviceId, wearData);
        },
        setAllWearablesData(state, wearData) {
            Vue.set(state, 'wearData', wearData || {});
        },
        setAllWearablesDataPerUser(state, { data, userId }) {
            Vue.set(state.wearDataPerUser, userId, data || {});
        }
    },
    actions: {
        async deleteWearable({ commit }, deviceId) {
            commit('activateLoading', `wearable/deleteWearable/${deviceId}`, { root: true });
            try {
                await api.deleteWearable(deviceId);
            } finally {
                commit('deactivateLoading', `wearable/deleteWearable/${deviceId}`, { root: true });
            }
        },
        async disconnectWearable({ commit }, deviceId) {
            commit('activateLoading', `wearable/disconnectWearable/${deviceId}`, { root: true });
            try {
                await api.disconnectWearable(deviceId);
            } finally {
                commit('deactivateLoading', `wearable/disconnectWearable/${deviceId}`, { root: true });
            }
        },
        async getWearableData({ commit, state }, { deviceId, dateFilter, current, withLoading = true }) {
            if (withLoading) commit('activateLoading', `wearable/getWearableData/${deviceId}`, { root: true });
            try {
                const response = await api.getWearableData(deviceId, dateFilter);

                if (current || state.currentWear?.deviceId === deviceId) {
                    commit('setCurrentWearData', response);

                    if (!current) {
                        commit('setWearData', response);
                    }
                } else {
                    commit('setWearData', response);
                }

                return response;
            } finally {
                if (withLoading) commit('deactivateLoading', `wearable/getWearableData/${deviceId}`, { root: true });
            }
        },
        async getAllWearableDataForAUser({ commit }, { userId, dateFilter }) {
            commit('activateLoading', 'wearable/getAllWearableDataForAPatient', { root: true });
            commit('activateLoading', `wearable/getAllWearableDataForAUser/${userId}`, { root: true });
            try {
                const response = await api.getAllWearableDataForUser(userId, dateFilter);
                commit('setAllWearablesData', response);
                commit('setAllWearablesDataPerUser', { userId, data: response });

                return response;
            } finally {
                commit('deactivateLoading', `wearable/getAllWearableDataForAUser/${userId}`, { root: true });
                commit('deactivateLoading', 'wearable/getAllWearableDataForAPatient', { root: true });
            }
        },
        async initiateWearableConnection({ commit }, userId) {
            commit('activateLoading', 'wearable/initiateWearableConnection', { root: true });
            try {
                const response = await api.initiateWearableConnection(userId);
                return response;
            } finally {
                commit('deactivateLoading', 'wearable/initiateWearableConnection', { root: true });
            }
        },
        async updateWearableOnAUser({ commit }, { userId, info }) {
            commit('activateLoading', `wearable/updateWearableOnAUser/${info.id}`, { root: true });
            commit('activateLoading', 'wearable/updateWearableOnAUser', { root: true });
            try {
                const response = await api.updateWearableOnAUser(userId, info);
                return response;
            } finally {
                commit('deactivateLoading', `wearable/updateWearableOnAUser/${info.id}`, { root: true });
                commit('deactivateLoading', 'wearable/updateWearableOnAUser', { root: true });
            }
        }
    }
};
