<template>
    <div>
        <div class="form-group mr-0 mr-sm-2 mb-2 w-100">
            <div class="search-bar w-100">
                <span id="search-addon" class="input-group-text border-0 bg-transparent">
                    <i class="fas fa-search" />
                </span>
                <input v-model="searchTerm" :disabled="isLoading" :placeholder="'Search for user name, email, id'" class="border-0" />
            </div>
        </div>
        <loader :listen="['user/getAllPatients']">
            <div class="users-table">
                <b-table
                    hover
                    show-empty
                    stacked="lg"
                    striped
                    :current-page="!noPagination ? currentPage : 1"
                    :fields="fields"
                    :items="users"
                    :per-page="0"
                >
                    <template #cell(name)="row">
                        <div class="d-flex w-100 align-items-center">
                            <img :src="row.item.profilePictureUrl" class="rounded-circle" width="40" height="40" />
                            <div class="d-flex flex-column w-100 ml-2">
                                {{ row.item.name }}
                                <small> {{ row.item.email }} </small>
                                <small class="text-muted"> {{ row.item.id }} </small>
                            </div>
                        </div>
                    </template>
                    <!-- <template #cell(roles)="row">
                <div class="d-flex w-100 align-items-center">
                    <div class="d-flex flex-column w-100 ml-2 small">
                        <span v-for="(role, index) in getAllRowRoles(row.item)" :key="row.item.id + index">{{ role }}</span>
                    </div>
                </div>
            </template> -->
                    <!-- <template #cell(pregnancyInfo)="row">
                <div class="d-flex flex-column">
                    <small>{{ USERS_TABLE.outcomeStatus }}</small>
                    <div>
                        <b-badge
                            pill variant="info" class="p-1 px-2">
                            <div class="d-flex flex-column">
                                {{ row.item.pregnancyOutcomeStatus?.status ? row.item.pregnancyOutcomeStatus.status.toUpperCase() : USERS_TABLE.unavailable }}
                                <small v-if="row.item.pregnancyOutcomeStatus?.status">{{ row.item.pregnancyOutcomeStatus.lastUpdatedDate }}</small>
                            </div>
                        </b-badge>
                    </div>
                </div>
            </template> -->
                    <!-- <template #cell(subscription)="row">
                <div class="d-flex flex-column">
                    <div class="d-flex w-100 align-items-center">
                        <div v-if="row.item?.validSubscriptions?.length" class="d-flex flex-column w-100 ml-2 small">
                            <span v-for="(subscription, index) in row.item.validSubscriptions" :key="row.item.id + index * 2">
                                <div v-if="subscription?.store">
                                    <details>
                                        <summary>{{ subscription?.store ?? '-' }}</summary>
                                        <span class="font-weight-bold">{{ USERS_TABLE.expiresOn }}</span><br>
                                        {{ formatDate(subscription.expires_date) }}
                                    </details>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </span>
                        </div>
                        <div v-else>
                            -
                        </div>
                    </div>
                </div>
            </template> -->
                    <!-- <template #cell(isOnTrial)="row">
                <div class="d-flex flex-column">
                    <div class="d-flex w-100 align-items-center">
                        <div class="d-flex flex-column w-100 ml-2 small">
                            <span v-for="(trial, index) in getAllRowTrials(row.item.trial)" :key="row.item.id + index * 3">{{ trial }}</span>
                        </div>
                    </div>
                </div>
            </template> -->
                    <template #cell(basicInfo)="row">
                        <small>
                            <b-badge v-if="!row.item.gender && !row.item.dob" pill variant="info" class="p-1 px-2">
                                Registration not finalized
                            </b-badge>
                            <div v-else class="d-flex flex-column text-left small">
                                <div v-if="row.item.gender">
                                    <div>
                                        <span class="font-weight-bold">{{ USERS_TABLE.genderLabel }} </span>{{ row.item.gender }}
                                    </div>
                                </div>
                                <div v-if="row.item.age">
                                    <span class="font-weight-bold">{{ USERS_TABLE.ageLabel }} </span>{{ row.item.age }} ({{ row.item.dob }})
                                </div>
                                <div v-if="row.item.height">
                                    <span class="font-weight-bold">{{ USERS_TABLE.heightLabel }} </span>
                                    <span v-if="loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id">
                                        {{ imperialConvertHeight(row.item.height) }}
                                    </span>
                                    <span v-else-if="!loggedUser.unitSystem || loggedUser.unitSystem === UNIT_SYSTEMS.metric.id">
                                        {{ row.item.height }} {{ UNIT_SYSTEMS.metric.heightUnitMain }}
                                    </span>
                                </div>
                                <div v-if="row.item.weight">
                                    <span class="font-weight-bold">{{ USERS_TABLE.weightLabel }} </span>
                                    <span v-if="loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id">
                                        {{ imperialConvertWeight(row.item.weight) }}
                                    </span>
                                    <span v-else-if="!loggedUser.unitSystem || loggedUser.unitSystem === UNIT_SYSTEMS.metric.id">
                                        {{ row.item.weight }} {{ UNIT_SYSTEMS.metric.weightUnit }}
                                    </span>
                                </div>
                            </div>
                        </small>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown id="dropdown-grouped" text="Actions" variant="primary" class="mx-2">
                            <b-dropdown-item-button @click="viewUser(row.item)">View user</b-dropdown-item-button>
                            <!-- <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-group v-can-access.role="'admin'" id="dropdown-group-1" header="Other">
                                <b-dropdown-item-button v-can-access.role="'admin'"> Amend Notes </b-dropdown-item-button>
                            </b-dropdown-group> -->
                        </b-dropdown>
                    </template>
                </b-table>
                <b-pagination
                    v-if="!noPagination"
                    v-model="currentPage"
                    align="center"
                    :total-rows="total"
                    :per-page="pageSize"
                    aria-controls="results-table"
                    @change="handlePageChange"
                />
            </div>
        </loader>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { USERS_TABLE, UNIT_SYSTEMS } from '../utils/constants';
import { cmToInch, inchToFeet, inchToRemainingInch, kgToLbs } from '../utils/utils';
import _ from 'lodash';

export default {
    props: {
        users: {
            type: Array,
            default: () => []
        },
        withoutFilters: {
            type: Boolean,
            default: false
        },
        noPagination: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            UNIT_SYSTEMS,
            USERS_TABLE,
            fields: [
                { key: 'name', label: 'Full Name', sortable: true },
                { key: 'basicInfo', sortable: false },
                // { key: 'roles', sortable: false },
                { key: 'actions', sortable: false }
            ],
            enableEditModal: false,
            currentPage: 1,
            rows: 0,
            perPage: 10,
            searchTerm: null,
            sortBy: '',
            sortDesc: false,
            unitSystem: UNIT_SYSTEMS.metric.id
        };
    },
    watch: {
        searchTerm(value) {
            this.debouncedSearch(value);
        },
        queryString: {
            handler(value) {
                this.searchTerm = value;
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.debouncedSearch = _.debounce(this.emitSearch, 700);

        this.currentPage = this.page;
    },
    computed: {
        ...mapGetters({
            loggedUser: 'user/currentUser',
            page: 'user/page',
            pageSize: 'user/pageSize',
            total: 'user/total',
            queryString: 'user/queryString'
        }),
        isLoading() {
            return !!this.$store.state.loading['user/getAllPatients'];
        },
        weightUnit() {
            if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.weightUnit;
            }

            return UNIT_SYSTEMS.metric.weightUnit;
        },
        heightUnit() {
            if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.heightUnitMain;
            }

            return UNIT_SYSTEMS.metric.heightUnitMain;
        }
    },
    methods: {
        viewUser(user) {
            this.$emit('userSelected', user);
        },
        getAllRowRoles(user) {
            return (user.tenants || [])
                .filter((tenant) => tenant.tenantId === this.loggedUser.currentTenant.id)
                .flatMap((tenant) => tenant.roles.map((role) => role.key));
        },
        formatDate(date) {
            return moment(date).format('DD MMM YYYY');
        },
        imperialConvertWeight(kg) {
            return `${kgToLbs(kg)} ${UNIT_SYSTEMS.imperial.weightUnit}`;
        },
        imperialConvertHeight(cm) {
            const inch = cmToInch(cm);
            const remainingInch = inchToRemainingInch(Number(inch));
            const feet = inchToFeet(Number(inch));
            return `${feet} ${UNIT_SYSTEMS.imperial.heightUnitSecondary} ${remainingInch} ${UNIT_SYSTEMS.imperial.heightUnitMain}`;
        },
        async handlePageChange(page) {
            this.currentPage = page;

            this.$emit('pageChange', { page, queryString: this.searchTerm });
        },
        emitSearch(value) {
            this.searchTerm = value;

            this.currentPage = 1;
            this.$emit('search', value);
        }
    }
};
</script>

<style lang="scss">
.users-table {
    .table-striped {
        th:last-child {
            width: unset;
        }

        td:last-child {
            height: 100%;
            padding: 0;

            .btn.btn-link {
                padding: 38% 38%;

                @media screen and (max-width: 992px) {
                    padding: 0% 0%;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .table-striped {
            th:last-child {
                width: 120px;
            }
        }
    }
}
</style>
