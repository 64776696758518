<template>
    <div>
        <div class="pb-3 w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
            <h4 class="text__page-header mt-2 w-100 d-flex justify-content-start">
                {{ PATIENT_MANAGEMENT.headline }}
            </h4>
            <!-- <button v-can-access.role="'admin'" class="btn btn-primary w-auto" @click.prevent="showAdminPortal('#/admin-box/users')">
                {{ BUTTONS.manage }}
            </button> -->
        </div>
        <div v-if="loggedUser.isDoctor || loggedUser.isAdmin">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <users-table :users="allPatients" @userSelected="viewUserInfo" @pageChange="onPageChange" @search="onSearchTerm" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AdminPortal } from '@frontegg/vue';
import { BUTTONS, PATIENT_MANAGEMENT } from '@/utils/constants';
import UsersTable from '../components/users-table';

export default {
    components: { UsersTable },
    data() {
        return {
            BUTTONS,
            PATIENT_MANAGEMENT,
            enableAddModal: false
        };
    },
    computed: {
        ...mapGetters({
            allPatients: 'user/allPatients',
            loggedUser: 'user/currentUser'
        })
    },
    async created() {
        await this.getAllPatients();
    },
    methods: {
        ...mapActions({
            getAllPatients: 'user/getAllPatients'
        }),
        showAdminPortal(url) {
            AdminPortal.show();
            // eslint-disable-next-line no-return-assign
            setTimeout(() => (window.location.href = url), 200);
        },
        viewUserInfo(user) {
            this.$router.push({ name: 'user-info', params: { userId: user.id } });
        },
        async onPageChange({ page, queryString }) {
            await this.getAllPatients({
                page,
                pageSize: this.pageSize,
                queryString
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllPatients({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm
            });
        }
    }
};
</script>
