import notify from '@/utils/notify';
import api from '../api/auth.api';

export const authWait = async (authTryCount, maxTryCount) => {
    const signupStatus = await api.getSignupStatus();
    authTryCount++;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (signupStatus.wait && authTryCount < maxTryCount) {
        return authWait(authTryCount, maxTryCount);
    }

    if (authTryCount > maxTryCount) {
        notify({
            type: 'error',
            text: 'Error while checking the user status. Please try again later.'
        });

        return;
    }

    return signupStatus.wait;
};
