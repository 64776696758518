<template>
    <loader :listen="['user/getUser']" class="pb-5">
        <div v-if="enabledCategory" class="w-100 p-3">
            <VueSlickCarousel v-bind="sliderSettings">
                <div
                    v-for="category in availableCategories"
                    :key="category.id"
                    class="clickable"
                    @click="
                        enabledCategory = category;
                        showEnabledCategoryDescription = enabledCategory.id === category.id ? showEnabledCategoryDescription : false;
                    "
                >
                    <div
                        class="card-inner m-2 border-0 category-tile text-center"
                        :class="enabledCategory.id === category.id ? 'category-active' : ''"
                    >
                        <div class="d-flex flex-column align-items-center">
                            <div
                                v-if="bloodInsights"
                                :class="`analysis-title__status extra-small
                        text-nowrap mb-2 ${getLabelVariant(category.id).color}`"
                            >
                                {{ getLabelVariant(category.id).text }}
                            </div>
                            <img :src="category.icon" height="25" /> {{ category.title }}
                            <small> {{ category.totalMarkers }} markers </small>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <div v-if="enabledCategory" class="category-header">
            <h4 class="category-header__h4 d-flex align-items-center">
                <img :src="enabledCategory.icon" height="28" class="mr-2" />
                <div class="d-flex flex-column align-items-start w-100">
                    <div>
                        {{ enabledCategory.title }}
                    </div>
                    <button
                        v-if="enabledCategory.description"
                        class="btn btn-link no_decoration btn-sm p-0 m-0 w-auto"
                        @click.prevent="showEnabledCategoryDescription = !showEnabledCategoryDescription"
                    >
                        Learn more <span class="ml-1"><font-awesome-icon icon="fa fa-info-circle" /></span>
                    </button>
                </div>
            </h4>
            <div v-if="windowWidth < 780 && enabledCategory.description && showEnabledCategoryDescription" class="card mb-2">
                <span class="card-inner border-0 p-3" style="white-space: pre-line">
                    {{ enabledCategory.description }}
                </span>
            </div>
            <div class="w-100 text-center">
                <ResultSummary
                    :result="result || bloodInsights"
                    :category="enabledCategory.id"
                    :show-result-button="false"
                    :align-center="true"
                    :center-on-mobile="true"
                />
            </div>
            <div class="d-flex mt-3">
                <b-dropdown :text="filterMarker.label" variant="light">
                    <b-dropdown-item
                        v-for="label in filterOptions"
                        :key="label.id"
                        :active="label.id === filterMarker.id"
                        @click="filterMarker = label"
                    >
                        {{ label.label }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div v-if="windowWidth >= 780 && enabledCategory.description && showEnabledCategoryDescription" class="card">
            <span class="card-inner border-0 p-3" style="white-space: pre-line">
                {{ enabledCategory.description }}
            </span>
        </div>
        <div v-if="enabledMarkers" class="row">
            <div v-if="!enabledMarkers.items.length" class="w-100 text-center">
                No markers found
                <div class="d-flex justify-content-center align-items-center">
                    <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
                </div>
            </div>
            <div v-for="marker in enabledMarkers.items[0]" :key="marker.id" class="d-flex col-12 p-0 px-2 col-md-6 col-xl-4">
                <div class="card mx-0 w-100 my-2">
                    <div class="card-inner d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <h5 class="analysis-title m-0">
                                {{ marker.testName }}
                                <span v-if="marker.description" v-b-tooltip.hover.top class="ml-1" :title="marker.description">
                                    <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                            </h5>
                            <small v-if="!isOverall"
                                >Value: {{ marker.numberValue }}
                                <small v-if="marker.units" style="color: rgba(60, 60, 60, 0.72)">
                                    {{ marker.units }}
                                </small>
                            </small>
                            <small v-else>Historical data </small>
                        </div>
                        <div class="d-flex flex-column align-items-end justify-content-center">
                            <div v-if="marker.abnormalFlags" class="d-flex flex-column align-items-center justify-content-center">
                                <div
                                    v-if="marker.abnormalFlags.totalGoodPercentage !== null && marker.abnormalFlags.totalGoodPercentage !== undefined"
                                    class="small text-center"
                                >
                                    {{ formatNumberWithDecimals(marker.abnormalFlags.totalGoodPercentage) }}% normal
                                </div>
                                <div :class="`analysis-title__status small text-nowrap ${getLabelStyle(marker)}`">
                                    {{ marker.abnormalFlags.message }}
                                </div>
                            </div>
                            <div v-if="!isOverall">
                                <b-form-checkbox
                                    v-if="getHistoricalDataPoints(marker, isOverall).data.length > 1"
                                    :key="`${marker.id}-${marker.testName}`"
                                    class="pt-1 small"
                                    switch
                                    size="sm"
                                    @click.prevent
                                    @change="
                                        enableHistory[`${marker.id}-${marker.testName}`] = !enableHistory[`${marker.id}-${marker.testName}`];
                                        updateChartOptions(marker, isOverall || !!enableHistory[`${marker.id}-${marker.testName}`]);
                                    "
                                >
                                    <small> <small> Historical data </small> </small>
                                </b-form-checkbox>

                                <small v-else class="mt-1 text-muted"><small>No historical data yet</small></small>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom-0 h-100 d-flex flex-column justify-content-center align-items-center h-100">
                        <highcharts
                            :ref="`${marker.id}-${marker.testName}`"
                            :key="`${marker.id}-${marker.testName}`"
                            class="chart-wrapper"
                            :options="getChartOptions(marker, isOverall)"
                        />
                        <div v-if="marker.comments" class="form-group text-center pb-3">
                            <label v-if="marker.comments" class="text-muted font-weight-bold"> Normal Ranges & Comments </label>
                            <span v-for="(comment, idx) in marker.comments" :key="idx"> <br />{{ comment }} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="marker in enabledMarkers.items[1]" :key="marker.id" class="d-flex col-12 p-0 p-2 col-md-6 col-xl-4">
                <div class="card mx-0 w-100">
                    <div class="card-inner d-flex flex-row justify-content-between align-items-center" :class="{ 'border-bottom-0': marker.invalid }">
                        <div>
                            <h5 class="analysis-title m-0">
                                {{ marker.testName
                                }}<span v-if="marker.description" v-b-tooltip.hover.top class="ml-1" :title="marker.description">
                                    <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                            </h5>
                            <small v-if="isOverall">Historical data </small>
                        </div>
                        <div v-if="marker.abnormalFlags" class="d-flex flex-column align-items-center justify-content-center">
                            <div
                                v-if="marker.abnormalFlags.totalGoodPercentage !== null && marker.abnormalFlags.totalGoodPercentage !== undefined"
                                class="small text-center"
                            >
                                {{ formatNumberWithDecimals(marker.abnormalFlags.totalGoodPercentage) }}% normal
                            </div>
                            <div :class="`analysis-title__status text-nowrap small ${getLabelStyle(marker)}`">
                                {{ marker.abnormalFlags.message }}
                            </div>
                        </div>
                    </div>
                    <div v-if="!marker.invalid" class="card-inner border-bottom-0 d-flex flex-column justify-content-center align-items-center h-100">
                        <div v-if="!isOverall" class="form-group text-center pb-3">
                            <label class="text-muted">Value: </label>
                            <span class="analysis-title"
                                >{{ marker.numberValue }}
                                <span v-if="marker.units" style="color: rgba(60, 60, 60, 0.72)">
                                    {{ marker.units }}
                                </span>
                            </span>
                        </div>
                        <div v-else class="form-group text-center pb-3">
                            <label class="text-muted">Historical values: </label>
                            <span v-for="(m, index) in marker.history" :key="index" class="analysis-title">
                                {{ m.observationValue }}
                                <span v-if="marker.units" class="text-muted small">
                                    {{ marker.units }}
                                </span>
                                <small>({{ formattedDateAdded(m.dateAdded) }})</small>
                            </span>
                        </div>
                        <div class="form-group text-center pb-1">
                            <label v-if="marker.referenceRange || (marker.comments && marker.comments.length)" class="text-muted font-weight-bold">
                                References - normal range
                            </label>
                            <span v-if="marker.referenceRange" class="analysis-title">
                                {{ marker.referenceRange }}
                                <span v-if="marker.units" style="opacity: 0.725">
                                    {{ marker.units }}
                                </span>
                            </span>
                            <span v-for="(comment, idx) in marker.comments" :key="idx">
                                <br />
                                {{ comment }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!formattedCategoriesWithMarkers.length" class="w-100 text-center mt-3">
            No markers found
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/svg/empty-state/empty-folder.svg" class="empty-state-image" />
            </div>
        </div>
    </loader>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Chart } from 'highcharts-vue';
import _ from 'lodash';
import moment from 'moment';
import VueSlickCarousel from 'vue-slick-carousel';
import {
    ABNORMAL_FLAGS,
    ABNORMAL_FLAGS_TAGGED,
    greenColor,
    darkGreyColor,
    redColor,
    rawLocalTest,
    blackGreyColor,
    bloodCategoryDetails
} from '@/utils/constants';
import ResultSummary from './result-summary';
import { formatNumberWithDecimals } from '../utils/utils';

export default {
    components: { highcharts: Chart, VueSlickCarousel, ResultSummary },
    props: {
        bloodInsights: {
            type: Object
        },
        categoriesWithMarkers: {
            type: Object
        },
        isOverall: {
            type: Boolean,
            default: false
        },
        result: {
            type: Object
        }
    },
    data() {
        return {
            formattedCategoriesWithMarkers: null,
            ABNORMAL_FLAGS,
            ABNORMAL_FLAGS_TAGGED,
            darkGreyColor,
            filterMarker: { id: 'all', label: 'All' },
            filterOptions: [
                { id: 'all', label: 'All' },
                { id: 'good', label: 'Good' },
                { id: 'bad', label: 'Deficient' },
                { id: 'undefined', label: 'Invalid' }
            ],
            enableHistory: {},
            chartOptions: {},
            sampleRawTest: rawLocalTest,
            availableCategories: [],
            enabledCategory: {},
            showEnabledCategoryDescription: false,
            windowWidth: window.innerWidth,
            sliderSettings: {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 1,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: false
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: false
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: false
                        }
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser', currentUser: 'user/currentUser' }),
        enabledMarkers() {
            const filterId = this.filterMarker.id;

            if (filterId === 'all') return this.defaultCategoriesWithMarkers.find((c) => c.categoryId === this.enabledCategory.id);

            const markersInCategory = this.defaultCategoriesWithMarkers.find((c) => c.categoryId === this.enabledCategory.id);
            const charts = markersInCategory.items[0];
            const textCard = markersInCategory.items[1];

            return {
                ...markersInCategory,
                items: [
                    charts.filter(
                        (itm) => ABNORMAL_FLAGS_TAGGED[itm.abnormalFlagCode] === filterId || (filterId === 'good' && itm.abnormalFlagCode === 'NAF')
                    ),
                    textCard.filter(
                        (itm) => ABNORMAL_FLAGS_TAGGED[itm.abnormalFlagCode] === filterId || (filterId === 'good' && itm.abnormalFlagCode === 'NAF')
                    )
                ].filter((x) => x.length)
            };
        }
    },
    async created() {
        this.formattedCategoriesWithMarkers = this.formatCatWithMarkers(this.categoriesWithMarkers || this.bloodInsights.categoriesWithMarkers);
        this.defaultCategoriesWithMarkers = _.cloneDeep(this.formattedCategoriesWithMarkers);
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        formatNumberWithDecimals,
        ...mapActions({ getUser: 'user/getUser' }),
        formatCatWithMarkers(catWithMarkers) {
            const catWithMarkersFormatted = [];

            _.each(catWithMarkers, (categoryData) => {
                if (categoryData?.markers.length) {
                    const categoryInfo = categoryData.categoryDetails;

                    const markersPerCat = {
                        category: categoryInfo.name,
                        categoryId: categoryInfo.id,
                        items: _(categoryData?.markers)
                            .map((marker) => {
                                return {
                                    testName: marker.testName,
                                    id: marker.setIdOBX,
                                    units: marker.units,
                                    referenceRange: marker.referenceRange,
                                    value: `${marker.observationValue} ${_.isNaN(Number(marker.observationValue)) ? '' : marker.units || ''}`,
                                    numberValue: _.isNaN(Number(marker.observationValue)) ? marker.observationValue : Number(marker.observationValue),
                                    invalid: marker.invalid,
                                    dateAdded: marker.dateAdded,
                                    description: marker.description,
                                    abnormalFlags: marker.abnormalFlags,
                                    abnormalFlagCode: marker.abnormalFlags.flag,
                                    observationResultStatus: marker.observationResultStatus.message,
                                    testInternalCode: marker.testInternalCode,
                                    comments: marker.comments ? marker.comments.map((c) => c.comment) : null,
                                    chartType: marker.chartType,
                                    history: marker.history
                                };
                            })
                            .orderBy('comments', 'desc')
                            // eslint-disable-next-line max-len
                            .partition(
                                (marker) =>
                                    (marker.chartType === 'linechart' || marker.chartType === 'goodbadchart') &&
                                    !marker.invalid &&
                                    !ABNORMAL_FLAGS[marker.abnormalFlagCode].undefined
                            )
                            .value()
                    };

                    this.availableCategories.push({
                        title: categoryInfo.name,
                        id: categoryInfo.id,
                        description: categoryInfo.description,
                        tips: categoryInfo.tips,
                        icon: bloodCategoryDetails[categoryInfo.id]?.icon || bloodCategoryDetails.default.icon,
                        totalMarkers: markersPerCat.items[0].length + markersPerCat.items[1].length
                    });

                    catWithMarkersFormatted.push(markersPerCat);
                }
            });

            this.sliderSettings.slidesToShow = this.availableCategories?.length < 5 ? this.availableCategories?.length : 5;
            this.sliderSettings.responsive = _.map(this.sliderSettings.responsive, (r) => {
                r.settings.slidesToShow =
                    this.availableCategories?.length < r.settings.slidesToShow ? this.availableCategories?.length : r.settings.slidesToShow;
                return r;
            });

            this.enabledCategory = this.availableCategories[0];

            return catWithMarkersFormatted;
        },
        getHistoricalDataPoints({ referenceRange, numberValue, units, history, chartType, dateAdded }, enableHistory) {
            if (!history || (history && !history.length)) return { data: [] };

            let ranges = null;
            let lowerBound = null;
            let higherBound = null;

            if (chartType === 'linechart') {
                ranges = referenceRange.split(' - ');
                higherBound = Number(ranges[1]);
                lowerBound = Number(ranges[0]);
            }

            let historicalValues = _.map(
                history?.filter((h) => !_.isNil(h.observationValue) && !_.isNaN(Number(h.observationValue))),
                (m) => {
                    return {
                        x: moment.utc(m.dateAdded).local().valueOf(),
                        y: _.isString(m.observationValue) ? Number(m.observationValue) : m.observationValue,
                        date: m.dateAdded
                    }; // to remove * 4
                }
            );

            if (!this.isOverall) {
                historicalValues = [
                    {
                        x: moment.utc(dateAdded).local().valueOf(),
                        y: numberValue,
                        marker: { enabled: true, symbol: 'circle', fillColor: '#1aadce', radius: 6 },
                        dataLabels: { enabled: true, format: `(Current) <br> {y} ${units || ''}` }
                    },
                    ...historicalValues
                ];
            }

            //   historicalValues = _.orderBy(historicalValues, 'x', 'desc');

            // The line chart that renders historical data
            return {
                data: _.orderBy(historicalValues, ['x'], ['asc']),
                name: 'Historical Value',
                // regression: historicalValues.length > 1,
                // regressionSettings: {
                //     name: 'Overall - Linear Trend',
                //     type: 'linear',
                //     color: 'grey',
                //     dashStyle: 'dash',
                // },
                zIndex: 6,
                yAxis: 1,
                xAxis: 1,
                dataLabels: {
                    enabled: enableHistory,
                    format: `{y} ${units || ''}`
                },
                color: blackGreyColor.color,
                marker: { symbol: 'circle', enabled: true },
                lineWidth: 1,
                tooltip: {
                    crosshairs: true,
                    outside: true,
                    pointFormatter() {
                        return chartType === 'linechart'
                            ? `<br>Value: <b>${this.y.toFixed(2)} ${units || ''}</b>
                        <br>Normal: <b>${lowerBound} ${units || ''} - ${higherBound} ${units || ''}</b>`
                            : `<br>Value: <b>${this.y.toFixed(2)} ${units || ''}</b>
                        <br>Normal: <b>${referenceRange} ${units || ''}</b>`;
                    }
                }
            };
        },
        getChartOptions(marker, enableHistory) {
            if (marker.chartType === 'linechart') {
                return this.getLinearGaugeChartOptions(marker, enableHistory);
            }
            if (marker.chartType === 'goodbadchart') {
                return this.getGoodBadChartOptions(marker, enableHistory);
            }
            return {};
        },
        getLinearGaugeChartOptions({ referenceRange, numberValue, testName, units, abnormalFlagCode, history, chartType, dateAdded }, enableHistory) {
            if (!referenceRange) return {};

            const ranges = referenceRange.split(' - ');

            const lowerBound = Number(ranges[0]);
            const higherBound = Number(ranges[1]);

            const historicalData = enableHistory
                ? this.getHistoricalDataPoints(
                      {
                          referenceRange,
                          numberValue,
                          units,
                          abnormalFlagCode,
                          history,
                          chartType,
                          dateAdded
                      },
                      enableHistory
                  )
                : null;
            let minExistingValue = numberValue;
            let maxExistingValue = numberValue;

            // if we have in history numbers way bigger/smaller than current test, we should normalize the chart to fit them
            if (enableHistory) {
                const values = historicalData.data.map((p) => p.y);
                values.push(numberValue);

                minExistingValue = _.min(values);
                maxExistingValue = _.max(values);
            }

            let absoluteHigh = higherBound + (20 / 100) * higherBound + Math.abs(maxExistingValue);
            let desiredDifference = absoluteHigh - higherBound;
            let absoluteLow = lowerBound - desiredDifference;

            if (minExistingValue < absoluteLow) {
                absoluteLow = minExistingValue - (20 / 100) * Math.abs(minExistingValue);
                desiredDifference = lowerBound - absoluteLow;
                absoluteHigh = higherBound + desiredDifference;
            }

            const normalArea = [
                [testName, lowerBound, higherBound],
                [testName, lowerBound, higherBound],
                [testName, lowerBound, higherBound]
            ];
            const leftSideArea = [
                [testName, absoluteLow, lowerBound],
                [testName, absoluteLow, lowerBound],
                [testName, absoluteLow, lowerBound]
            ];
            const rightSideArea = [
                [testName, higherBound, absoluteHigh],
                [testName, higherBound, absoluteHigh],
                [testName, higherBound, absoluteHigh]
            ];

            const patientValue = [
                { y: numberValue, marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } } },
                {
                    y: numberValue,
                    marker: { enabled: true },
                    dataLabels: {
                        enabled: true,
                        inside: false,
                        y: -5,
                        crop: false,
                        overflow: 'none',
                        format: `{y} ${units || ''}`,
                        style: {
                            fontSize: '10px'
                        }
                    }
                },
                { y: numberValue, marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } } }
            ];

            const vm = this;

            const options = {
                chart: {
                    inverted: !enableHistory,
                    height: enableHistory ? 400 : this.windowWidth < 326 ? 131 : this.windowWidth < 355 ? 120 : this.windowWidth > 1919 ? 120 : 120,
                    zoomType: enableHistory ? 'xy' : null
                },
                boost: {
                    enabled: true,
                    allowForce: true,
                    usePreallocated: true,
                    useGPUTranslations: true
                },
                plotOptions: {
                    series: {
                        animation: false,
                        stickyTracking: false
                    }
                },
                title: { text: null },
                tooltip: {},
                xAxis: [
                    {
                        type: 'string',
                        visible: false,
                        width: '2.5%'
                    },
                    {
                        type: 'datetime',
                        visible: enableHistory,
                        width: '90%',
                        left: '5%',
                        labels: {
                            format: '{value:%Y-%m-%d}'
                        }
                    }
                ],
                yAxis: [
                    {
                        title: { text: null },
                        tickPositions: [absoluteLow, lowerBound, higherBound, absoluteHigh],
                        zIndex: 3,
                        labels: {
                            enabled: true,
                            useHTML: true,
                            formatter: function () {
                                if (!this.isLast && !this.isFirst) {
                                    return `<span class="text-center">${this.value} <br> <small>${units || ''}</small></span>`;
                                }
                            },
                            allowOverlap: false
                        }
                    },
                    {
                        title: {
                            text: null
                        },
                        visible: enableHistory,
                        tickPositions: [absoluteLow, lowerBound, higherBound, absoluteHigh],
                        plotBands: [
                            {
                                color: greenColor.lightColor,
                                from: lowerBound,
                                to: higherBound
                            }
                        ],
                        labels: {
                            enabled: false,
                            allowOverlap: false
                        }
                    }
                ],
                series: [
                    {
                        name: 'Below Normal',
                        data: leftSideArea,
                        type: 'arearange',
                        lineWidth: 0,
                        linkedTo: ':previous',
                        color: redColor.color,
                        tooltip: {
                            crosshairs: true,
                            outside: true,
                            headerFormat: null,
                            valueSuffix: units || '',
                            pointFormatter() {
                                return `<br>Below Normal: <b><${lowerBound} ${units || ''}</b>`;
                            }
                        },
                        zIndex: 0,
                        marker: {
                            enabled: false,
                            radius: 0,
                            states: { hover: { radius: 0 } }
                        }
                    },
                    {
                        name: 'Above Normal',
                        data: rightSideArea,
                        tooltip: {
                            crosshairs: true,
                            outside: true,
                            headerFormat: null,
                            valueSuffix: units || '',
                            pointFormatter() {
                                return `<br>Above Normal: <b>>${higherBound} ${units || ''}</b>`;
                            }
                        },
                        type: 'arearange',
                        borderRadius: 8,
                        lineWidth: 0,
                        linkedTo: ':previous',
                        color: redColor.color,
                        zIndex: 0,
                        marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                    },
                    this.isOverall
                        ? null
                        : {
                              name: `Value: ${numberValue} ${units || ''}`,
                              data: patientValue,
                              zIndex: 1,
                              color: blackGreyColor.color,
                              lineWidth: 2,
                              visible: !enableHistory,
                              marker: {
                                  fillColor: blackGreyColor.color,
                                  lineWidth: 2,
                                  radius: 3,
                                  symbol: 'circle',
                                  lineColor: blackGreyColor.color,
                                  enabled: false
                              },
                              tooltip: {
                                  crosshairs: true,
                                  outside: true,
                                  headerFormat: null,
                                  pointFormatter() {
                                      const text = !vm.currentUser.isPatient ? "Patient's Value" : 'Value';

                                      return `Marker: <b>${testName}</b>
                        <br>${text}: <b>${this.y.toFixed(2)} ${units || ''}</b>
                        <br>Normal: <b>${lowerBound} ${units || ''} - ${higherBound} ${units || ''}</b>`;
                                  }
                              }
                          },
                    {
                        name: 'Normal Range',
                        data: normalArea,
                        type: 'arearange',
                        lineWidth: 0,
                        tooltip: {
                            crosshairs: true,
                            outside: true,
                            headerFormat: null,
                            pointFormatter() {
                                return `<br>Normal: <b>${lowerBound} ${units || ''} - ${higherBound} ${units || ''}</b>`;
                            }
                        },
                        color: greenColor.color,
                        zIndex: 0,
                        marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                    }
                ].filter((x) => x)
            };

            if (historicalData) {
                options.series.push(historicalData);
            }

            return options;
        },
        getGoodBadChartOptions({ referenceRange, numberValue, testName, units, abnormalFlagCode, history, chartType, dateAdded }, enableHistory) {
            if (!referenceRange) return {};

            // ex: '<50' or '>30'
            const sign = referenceRange[0] === '<' ? '<' : '>';
            const threshold = Number(referenceRange.split(sign)[1]);

            const historicalData = enableHistory
                ? this.getHistoricalDataPoints({ referenceRange, numberValue, units, abnormalFlagCode, history, chartType, dateAdded }, enableHistory)
                : null;
            let minExistingValue = numberValue;
            let maxExistingValue = numberValue;

            // if we have in history numbers way bigger/smaller than current test, we should normalize the chart to fit them
            if (enableHistory) {
                const values = historicalData.data.map((p) => p.y);
                values.push(numberValue);

                minExistingValue = _.min(values);
                maxExistingValue = _.max(values);
            }

            let aboveThresholdLimit = threshold + (20 / 100) * threshold + Math.abs(maxExistingValue);
            let desiredDifference = aboveThresholdLimit - threshold;

            let underThresholdLimit = threshold - desiredDifference;

            if (minExistingValue < underThresholdLimit) {
                underThresholdLimit = minExistingValue - (20 / 100) * Math.abs(minExistingValue);
                desiredDifference = threshold - underThresholdLimit;
                aboveThresholdLimit = threshold + desiredDifference;
            }

            const leftSideArea = [
                [testName, underThresholdLimit, threshold],
                [testName, underThresholdLimit, threshold],
                [testName, underThresholdLimit, threshold]
            ];
            const rightSideArea = [
                [testName, threshold, aboveThresholdLimit],
                [testName, threshold, aboveThresholdLimit],
                [testName, threshold, aboveThresholdLimit]
            ];

            const patientValue = [
                { y: numberValue, dataLabels: { enabled: false }, marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } } },
                {
                    y: numberValue,
                    marker: { enabled: true },
                    dataLabels: {
                        enabled: true,
                        inside: false,
                        y: -6,
                        crop: false,
                        overflow: 'none',
                        format: `{y} ${units || ''}`,
                        style: {
                            fontSize: '10px'
                        }
                    }
                },
                { y: numberValue, dataLabels: { enabled: false }, marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } } }
            ];

            // eslint-disable-next-line max-len
            const leftSideLabel = [
                {
                    y: (underThresholdLimit + threshold) / 2,
                    dataLabels: { enabled: false },
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                },
                {
                    y: (underThresholdLimit + threshold) / 2,
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } },
                    dataLabels: { enabled: true }
                },
                {
                    y: (underThresholdLimit + threshold) / 2,
                    dataLabels: { enabled: false },
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                }
            ];

            // eslint-disable-next-line max-len
            const rightSideLabel = [
                {
                    y: (aboveThresholdLimit + threshold) / 2,
                    dataLabels: { enabled: false },
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                },
                {
                    y: (aboveThresholdLimit + threshold) / 2,
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } },
                    dataLabels: { enabled: true }
                },
                {
                    y: (aboveThresholdLimit + threshold) / 2,
                    dataLabels: { enabled: false },
                    marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                }
            ];

            const options = {
                chart: {
                    inverted: !enableHistory,
                    height: enableHistory ? 400 : this.windowWidth < 345 ? 90 : this.windowWidth < 355 ? 85 : 84,
                    zoomType: enableHistory ? 'xy' : null
                },
                boost: {
                    enabled: true,
                    allowForce: true,
                    usePreallocated: true,
                    useGPUTranslations: true
                },
                plotOptions: {
                    series: {
                        animation: false,
                        stickyTracking: false
                    }
                },
                title: { text: '' },
                tooltip: {},
                xAxis: [
                    {
                        type: 'string',
                        visible: false,
                        width: '2.5%'
                    },
                    {
                        type: 'datetime',
                        visible: enableHistory,
                        width: '90%',
                        left: '10%'
                    }
                ],
                yAxis: [
                    {
                        title: { text: null },
                        tickPositions: [underThresholdLimit, threshold, aboveThresholdLimit],
                        labels: {
                            enabled: false
                        },
                        zIndex: 3
                    },
                    {
                        title: {
                            text: null
                        },
                        visible: enableHistory,
                        tickPositions: [underThresholdLimit, threshold, aboveThresholdLimit],
                        plotBands: [
                            {
                                color: greenColor.lightColor,
                                from: threshold,
                                to: sign === '>' ? aboveThresholdLimit : underThresholdLimit
                            }
                        ],
                        labels: {
                            enabled: false
                        }
                    }
                ],
                series: [
                    {
                        name: sign === '>' ? `Below Normal: <${threshold}` : `Normal: <${threshold}`,
                        data: leftSideArea,
                        type: 'arearange',
                        label: {
                            onArea: true,
                            style: {
                                color: '#000',
                                fontSize: '8px'
                            }
                        },
                        lineWidth: 0,
                        color: sign === '>' ? redColor.color : greenColor.color,
                        zIndex: 0,
                        showInLegend: sign === '<',
                        enableMouseTracking: false,
                        marker: {
                            enabled: false,
                            radius: 0,
                            states: { hover: { radius: 0 } }
                        }
                    },
                    {
                        name: sign === '<' ? `Above Normal: >${threshold} ` : `Normal: >${threshold}`,
                        data: rightSideArea,
                        type: 'arearange',
                        showInLegend: sign === '>',
                        label: {
                            onArea: true,
                            style: {
                                color: '#000',
                                fontSize: '8px'
                            }
                        },
                        lineWidth: 0,
                        color: sign === '<' ? redColor.color : greenColor.color,
                        zIndex: 0,
                        enableMouseTracking: false,
                        marker: { enabled: false, radius: 0, states: { hover: { radius: 0 } } }
                    },
                    this.isOverall
                        ? null
                        : {
                              name: `Value: ${numberValue} ${units || ''}`,
                              data: patientValue,
                              zIndex: 1,
                              color: blackGreyColor.color,
                              lineWidth: 2,
                              visible: !enableHistory,
                              marker: {
                                  fillColor: blackGreyColor.color,
                                  lineWidth: 2,
                                  radius: 3,
                                  symbol: 'circle',
                                  lineColor: blackGreyColor.color,
                                  enabled: false
                              },
                              tooltip: {
                                  crosshairs: true,
                                  outside: true,
                                  headerFormat: null,
                                  valueSuffix: units || '',
                                  pointFormatter() {
                                      const text = 'Value';

                                      return `Marker: <b>${testName}</b>
                        <br>${text}: <b>${this.y.toFixed(2)} ${units || ''}</b>
                        <br>Normal: <b>${referenceRange} ${units || ''}</b>`;
                                  }
                              }
                          },
                    {
                        zIndex: 5,
                        data: leftSideLabel,
                        color: sign === '>' ? redColor.color : greenColor.color,
                        lineWidth: 0,
                        visible: true,
                        showInLegend: false,

                        dataLabels: {
                            enabled: true,
                            format: `<${threshold} ${units || ''}`,
                            y: 10
                        },
                        marker: { visible: false },
                        enableMouseTracking: false
                    },
                    {
                        zIndex: 5,
                        data: rightSideLabel,
                        color: sign === '<' ? redColor.color : greenColor.color,
                        lineWidth: 0,
                        visible: true,
                        showInLegend: false,
                        dataLabels: {
                            enabled: true,
                            format: `>${threshold} ${units || ''}`,
                            y: 10
                        },
                        marker: { visible: false },
                        enableMouseTracking: false
                    }
                ].filter((x) => x)
            };

            if (historicalData) {
                options.series.push(historicalData);
            }

            return options;
        },
        updateChartOptions(marker, enableHistory) {
            const newOptions = this.getChartOptions(marker, enableHistory);

            this.$refs[`${marker.id}-${marker.testName}`][0].chart.update(newOptions, true, true);
        },
        getLabelStyle(marker) {
            // eslint-disable-next-line no-nested-ternary
            return marker.invalid ? darkGreyColor.icon : marker.abnormalFlagCode ? ABNORMAL_FLAGS[marker.abnormalFlagCode].icon : greenColor.icon;
        },
        formattedDateAdded(marker) {
            return moment(marker.dateAdded).format('DD/MM/YYYY');
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        getLabelVariant(categoryId) {
            const value = this.bloodInsights.bloodHealthSummary[categoryId].good;
            if (value >= 70) return { color: 'green', text: 'Normal' };
            if (value >= 50 && value < 70) return { color: 'yellow', text: 'Acceptable' };
            if (value < 50) return { color: 'red', text: 'Abnormal' };
        }
    }
};

//         {
//   "chart": {
//     "type": "bullet",
//     "inverted": true,
//   },
//   "title": {text: null},
//   "exporting": {
//     "buttons": {
//       "contextButton": {
//         "enabled": false,
//   },
//     },
//   },
//   "xAxis": {
//     "lineColor": "#C0D0E0",
//     "lineWidth": 1,
//     "labels": {
//       "enabled": false
//     },
//     "tickLength": 0
//   },
//   "yAxis": {
//     "min": 0,
//     "max": 160,
//     "startOnTick": false,
//     "endOnTick": false,
//     "tickLength": 10,
//     "tickWidth": 1,
//     "tickColor": "",
//     "tickInterval": null,
//     "tickPosition": "outside",
//     "gridLineColor": "#C0D0E0",
//     "gridLineWidth": 1,
//     "minorTickColor": "",
//     "minorTickInterval": 0,
//     "minorTickPosition": "outside",
//     "minorTickLength": 2,
//     "minorTickWidth": 0,
//     "minorGridLineWidth": 1,
//     "title": {
//       "text": "",
//       "align": "middle",
//       "rotation": 0,
//       "offset": null,
//       "margin": 0,
//       "style": {
//         "color": "#333333",
//         "fontWeight": "normal",
//         "textDecoration": "normal",
//         "fontSize": "14px",
//         "fontFamily": "Lucida Grande,Lucida Sans Unicode, Arial, Helvetica, sans-serif",
//         "display": "none"
//       }
//     },
//     "labels": {
//       "enabled": true,
//     },
//     "plotBands": [
//       {
//         "from": 0,
//         "to": 40,
//         "color": "rgba(228,74,0,0.8)"
//       },
//       {
//         "from": 40,
//         "to": 80,
//         "color": "rgba(248,189,25,0.8)"
//       },
//       {
//         "from": 80,
//         "to": 100,
//         "color": "rgba(107,170,1,0.8)"
//       },
//       {
//         "from": 100,
//         "to": 160,
//         "color": "rgba(228,74,0,0.8)"
//       },
//     ]
//   },
//   "legend": {
//     "enabled": true
//   },
//   "series": [
//     {
//       "name": "work",
//       pointPadding: 0,
//       groupPadding: 0,
//       borderWidth: 0,
//       "data": [{
//         target: 25,
//         y: 25
//       }],
//       targetOptions: {
//          color: 'black'
//       },
//       "color": "transparent",
//       "tooltip": {
//          "enabled":true,
//       },
//       "dataLabels": {
//         "enabled": false,
//         "align": "center",
//         "allowOverlap": true,
//         "crop": true,
//         "defer": true,
//         "backgroundColor": "#ffffff",
//         "borderColor": "#000000",
//         "borderRadius": 1,
//         "borderWidth": 1,
//         "padding": 5,
//         "rotation": 0,
//         "verticalAlign": "top",
//         "x": 0,
//         "y": 0,
//       }
//     }
//   ]

// }
</script>
<style lang="scss">
.card-body {
    .card-text {
        text-align: left;
        padding: 0 18px;
    }
}

.open-category-charts {
    display: flex !important;
    justify-content: end !important;
}

@media screen and (max-width: 778px) {
    .filter-button-group {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .open-category-charts {
        display: flex !important;
        justify-content: undefined;
        align-items: start !important;
    }
}

.filter-button-group {
    display: flex;
}

.w-35 {
    width: 35% !important;
}

.card-inner {
    padding: 16px 8px !important;
}

.category-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &__h4 {
        width: 30%;
        text-align: left;
    }

    @media screen and (max-width: 778px) {
        flex-direction: column;

        &__h4 {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
        }
    }
}

// .learn-more-category-btn {
//     margin-top: -18px;
//     margin-right: -10px;

//     @media screen and (max-width: 778px) {
//         margin-top: 49px;
//         margin-left: 37px;
//     }
// }
</style>
