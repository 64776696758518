<template>
    <div class="profile-dropdown">
        <div v-if="showDropdown" class="dropdown-content">
            <router-link class="router-link-no-underline dropdown-link" :to="{ path: '/profile' }">
                <i class="fa-solid fa-gear" /><span>Settings</span>
            </router-link>
            <router-link class="router-link-no-underline dropdown-link" :to="{ path: '/logout' }">
                <i class="fas fa-sign-out-alt" /><span>Logout</span>
            </router-link>
        </div>
        <div class="icon-link" @click="toggleDropdown">
            <i class="fa-solid fa-user" />
            <span>Profile</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showDropdown: false
        };
    },
    watch: {
        $route() {
            this.showDropdown = false;
        }
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        }
    }
};
</script>
