/* eslint-disable no-unused-vars */
import api from '../api/stripe.api';

export default {
    namespaced: true,
    actions: {
        async createCheckoutSession({ commit }, payload) {
            commit('activateLoading', 'stripe/createCheckoutSession', { root: true });
            try {
                const session = await api.createCheckoutSession(payload);

                return session;
            } catch (e) {
                console.log(e);
            } finally {
                commit('deactivateLoading', 'stripe/createCheckoutSession', { root: true });
            }
        },
        async getAvailableSubscriptions({ commit }, usedFor = 'access') {
            commit('activateLoading', 'stripe/subscriptions', { root: true });
            try {
                const subs = await api.getLolaSubscriptions(usedFor);

                return subs;
            } catch (e) {
                console.log(e);
            } finally {
                commit('deactivateLoading', 'stripe/subscriptions', { root: true });
            }
        },
        async getStripeDashboardInstance({ commit }) {
            commit('activateLoading', 'stripe/getStripeDashboardInstance', { root: true });
            try {
                const response = await api.getDashboardSession();
                if (response) window.location.href = response;
                return response;
            } finally {
                commit('deactivateLoading', 'stripe/getStripeDashboardInstance', { root: true });
            }
        },
        async getStripePaymentIntent({ commit }) {
            commit('activateLoading', 'stripe/getStripePaymentIntent', { root: true });
            try {
                const response = await api.getStripePaymentIntent();
                return response;
            } finally {
                commit('deactivateLoading', 'stripe/getStripePaymentIntent', { root: true });
            }
        },
        async paySubscriptions({ commit }, payload) {
            commit('activateLoading', 'stripe/paySubscriptions', { root: true });
            try {
                const response = await api.paySubscriptions(payload);
                return response;
            } finally {
                commit('deactivateLoading', 'stripe/paySubscriptions', { root: true });
            }
        },
        async retrieveStripeSetupIntent({ commit }, setupIntentId) {
            commit('activateLoading', 'stripe/retrieveStripeSetupIntent', { root: true });
            try {
                const response = await api.retrieveStripeSetupIntent({ setupIntentId });
                return response;
            } finally {
                commit('deactivateLoading', 'stripe/retrieveStripeSetupIntent', { root: true });
            }
        },
        async setDefaultPayment({ commit }, payload) {
            commit('activateLoading', 'stripe/setDefaultPayment', { root: true });
            try {
                const response = await api.setDefaultPayment(payload);
                return response;
            } finally {
                commit('deactivateLoading', 'stripe/setDefaultPayment', { root: true });
            }
        }
    }
};
